import { LinkShape } from 'models/Link';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsCartPark, AnalyticsFacility } from 'models/Analytics';
import { LinksMap } from 'store/Auth/Links/useBaseLinks';
import { makeMapForLinks } from '_utils/links';

type FacilityAnalyticsState = {
  selected: AnalyticsFacility | undefined;
  carParks: AnalyticsCartPark[] | undefined;
  links: LinkShape[] | undefined;
  linksMap: LinksMap | undefined;
};

const initialState: FacilityAnalyticsState = {
  selected: undefined,
  carParks: undefined,
  links: undefined,
  linksMap: undefined,
};

const AnalyticsFacilitySlice = createSlice({
  name: 'facility_analytics',
  initialState,
  reducers: {
    setSelected(
      state: FacilityAnalyticsState,
      action: PayloadAction<AnalyticsCartPark | undefined>,
    ) {
      const updated = action.payload;
      state.selected = updated;
      if (updated === undefined) {
        state.carParks = undefined;
      }
    },
    setCarParks(
      state: FacilityAnalyticsState,
      action: PayloadAction<AnalyticsCartPark[]>,
    ) {
      state.carParks = action.payload;
    },
    setLinks(
      state: FacilityAnalyticsState,
      action: PayloadAction<LinkShape[] | undefined>,
    ) {
      const links = action.payload;
      state.links = links;
      state.linksMap = links ? makeMapForLinks(links) : undefined;
    },
  },
});

export const { setSelected, setCarParks, setLinks } =
  AnalyticsFacilitySlice.actions;

export const AnalyticsFacilityReducer = AnalyticsFacilitySlice.reducer;

export default AnalyticsFacilitySlice;
