import { FC, useRef, useState } from 'react';

import {
  IconButton,
  Box,
  List,
  ListItem,
  Typography,
  ListItemText,
  alpha,
  Popover,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';

import { US, NO, FI, SE } from 'country-flag-icons/react/3x2';

import { useTranslation } from 'react-i18next';
import useAuth from 'store/Auth/useAuth';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`,
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`,
);

type LanguageType = {
  lng: string;
};

type FlagProps = {
  type: 'en' | 'no' | 'fi' | 'se' | string;
};
const Flag: FC<FlagProps> = ({ type }) => {
  const flag = () => {
    switch (type) {
      case 'no':
        return <NO />;

      case 'fi':
        return <FI />;
      case 'se':
        return <SE />;

      default:
        return <US />;
    }
  };
  return <div style={{ width: '30px' }}>{flag()}</div>;
};

const LanguageSwitcher = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const language = window.localStorage.getItem('Language');
  const { profile: user } = useAuth();

  const [selectedLanguage, setSelectedLanguage] = useState(
    language ? language : user?.Language,
  );
  const theme = useTheme();

  const switchLanguage = ({ lng }: LanguageType) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
    window.localStorage.setItem('Language', lng);
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Box style={{ maxWidth: '100px', display: 'flex', alignItems: 'center' }}>
      <Tooltip arrow title={'Language switcher'}>
        <IconButtonWrapper
          color="secondary"
          ref={ref}
          onClick={handleOpen}
          style={{ margin: 'auto' }}
          sx={{
            background: alpha(theme.colors.alpha.black[5], 0.1),
            transition: `${theme.transitions.create(['background'])}`,
            color: theme.colors.error.main,

            '&:hover': {
              background: alpha(theme.colors.error.main, 0.2),
            },
          }}
        >
          <Flag type={selectedLanguage ?? ''} />
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            maxWidth: 240,
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t('Language switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2,
            }}
            component="nav"
          >
            <ListItem
              className={
                selectedLanguage === 'en' || selectedLanguage === 'en-US'
                  ? 'active'
                  : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
            >
              <Flag type="en" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary={t('English')}
              />
            </ListItem>

            <ListItem
              className={selectedLanguage === 'no' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'no' });
                handleClose();
              }}
            >
              <Flag type="no" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary={t('Norwegian')}
              />
            </ListItem>

            <ListItem
              className={selectedLanguage === 'fi' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'fi' });
                handleClose();
              }}
            >
              <Flag type="fi" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary={t('Finnish')}
              />
            </ListItem>

            <ListItem
              className={selectedLanguage === 'se' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'se' });
                handleClose();
              }}
            >
              <Flag type="se" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary={t('Swedish')}
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default LanguageSwitcher;
