import React, { FC, ReactNode } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { themeCreator } from './base';

export const ThemeContext = React.createContext('PureLightTheme');

type Props = {
  children: ReactNode | ReactNode[];
};
const ThemeProviderWrapper: FC<Props> = ({ children }) => {
  const theme = themeCreator('PureLightTheme');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value="PureLightTheme">
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
