import { Grid, Typography, Theme, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ISearchBoxItemProps {
  text: string;
  secondaryText?: string;
  icon: ReactNode;
  actions?: ReactNode;
  selectQuery?: (selectItem: string) => void;
  setViewUser?: () => void;
  viewSubscriptionModal?: () => void;
  viewTransactionModal?: () => void;
  viewVehicleModal?: () => void;
}
export const SearchBoxItem: FC<ISearchBoxItemProps> = (props): JSX.Element => {
  const theme = useTheme<Theme>();
  const textGridSize = props.secondaryText ? 6 : 9;
  return (
    <Grid
      container
      marginTop={theme.margins.xTiny}
      style={{
        background: theme.colors.alpha.black['10'],
        borderRadius: theme.general.borderRadius,
      }}
      padding={theme.padding.xTiny}
    >
      <Grid
        xs={1}
        md={1}
        lg={1}
        display="flex"
        flexDirection="row"
        alignItems={'center'}
        item
      >
        {props.icon}
      </Grid>
      <Grid
        xs={textGridSize}
        md={textGridSize}
        lg={textGridSize}
        display="flex"
        flexDirection="row"
        alignItems={'center'}
        sx={{ cursor: 'pointer' }}
        item
        onClick={() => {
          props.selectQuery?.(props.text);
          props.setViewUser?.();
          props.viewSubscriptionModal?.();
          props.viewTransactionModal?.();
          props.viewVehicleModal?.();
        }}
      >
        <Typography paddingY={theme.margins.xTiny} variant="h5" component="h5">
          {props.text}
        </Typography>
      </Grid>
      {props.secondaryText && (
        <Grid
          xs={3}
          md={3}
          lg={3}
          display="flex"
          flexDirection="row"
          alignItems={'center'}
          item
        >
          <Typography
            paddingY={theme.margins.xTiny}
            variant="h5"
            component="h5"
          >
            {props.secondaryText}
          </Typography>
        </Grid>
      )}

      {props.actions && (
        <Grid
          xs={2}
          md={2}
          lg={2}
          item
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          {props.actions}
        </Grid>
      )}
    </Grid>
  );
};
