import { FC, useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';

import './SuspenseLoader.scss';

type Props = {
  isFullScreen?: boolean;
  alignItems?: string;
};

const SuspenseLoader: FC<Props> = ({
  isFullScreen = true,
  alignItems = 'center',
}): JSX.Element => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      className="suspense-loader"
      display="flex"
      alignItems={alignItems}
      justifyContent="center"
    >
      <CircularProgress size={50} disableShrink thickness={3} />
    </Box>
  );

  return (
    <Box
      //sx={style}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={'suspense-loader' + (isFullScreen ? ' fullscreen-loader' : '')}
    >
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  );
};

export default SuspenseLoader;
