import { useState, useCallback, useEffect, FC } from 'react';
import useRefMounted from 'hooks/useRefMounted';
import axios from '_utils/axios';
import {
  Box,
  Select,
  MenuItem,
  TextField,
  TextareaAutosize,
  InputLabel,
  TextFieldProps,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { VehicleShape } from 'models/EndUsers';

interface FUELTYPE_SHAPE {
  Id: string;
  Name: string;
}

interface GREEN_STANDARD_SHAPE {
  Id: string;
  Name: string;
}

interface FUEL_AGREEMENT_SHAPE {
  Id: string;
  Name: string;
}

interface Props {
  vehicleData: VehicleShape;
}

const EditVehicle: FC<Props> = ({ vehicleData }) => {
  const isMountedRef = useRefMounted();
  const [greenStandard, setGreenStandardValue] = useState('');
  const [fuelType, setFuelTypeValue] = useState('');
  const [fuelAgreement, setFuelAgreement] = useState('');

  // const [bonusSettingsData, setBonusSettings] = useState([]);
  const [fuelAgreementData, setFuelAgreementsData] = useState<
    FUEL_AGREEMENT_SHAPE[]
  >([]);
  const [fuelTypesData, setFuelTypesData] = useState<FUELTYPE_SHAPE[]>([]);
  const [greeenStandardData, setGreenStandardData] = useState<
    GREEN_STANDARD_SHAPE[]
  >([]);

  const urls = [
    'available-fuel-agreements',
    'available-fuel-types',
    'available-green-standards',
    'bonus-settings',
  ];
  const dataUrl = vehicleData?.Links.filter(
    (item) => urls.includes(item.Rel) && item.Href,
  );
  const [value, setValue] = useState<Date | null>(null);

  // const bonus_Url = dataUrl[0].Href.split('admin/').at(-1);
  const fuelAgreement_Url = dataUrl[1]?.Href.split('admin/').at(-1);
  const fuelTypes_Url = dataUrl[2]?.Href.split('admin/').at(-1);
  const greenStandards_Url = dataUrl[3]?.Href.split('admin/').at(-1);

  const getVehicleDetails = useCallback(async () => {
    try {
      // const bonus_Response = await axios.get(`/api/admin/${bonus_Url}`);
      const fuelAgreement_Response = await axios.get(
        `/v0/admin/${fuelAgreement_Url}`,
      );
      const fuelTypes_Response = await axios.get(`/v0/admin/${fuelTypes_Url}`);
      const greenStandards_Response = await axios.get(
        `/v0/admin/${greenStandards_Url}`,
      );
      if (isMountedRef.current) {
        // setBonusSettings(bonus_Response.data);
        setFuelAgreementsData(fuelAgreement_Response.data);
        setFuelTypesData(fuelTypes_Response.data);
        setGreenStandardData(greenStandards_Response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [fuelAgreement_Url, fuelTypes_Url, greenStandards_Url, isMountedRef]);

  useEffect(() => {
    getVehicleDetails();
  }, [getVehicleDetails]);

  return (
    <>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '50ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            label="License plate"
            id="outlined-size-normal"
            sx={{ mr: 3 }}
            value={vehicleData?.VehicleGuid}
          />
          <InputLabel id="demo-simple-select-required-label">
            Green vehicle standard
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Green vehicle standard"
            sx={{ width: '40%', mr: 3 }}
            value={greenStandard}
            onChange={(e) => setGreenStandardValue(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {greeenStandardData?.map((typeItem, index) => (
              <MenuItem key={index} value={typeItem.Id}>
                {typeItem.Name}
              </MenuItem>
            ))}
          </Select>
          <InputLabel id="demo-simple-select-required-label">
            Fuel type
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Fuel type"
            sx={{ width: '40%' }}
            value={fuelType}
            onChange={(e) => setFuelTypeValue(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {fuelTypesData?.map((typeItem, index) => (
              <MenuItem key={index} value={typeItem.Id}>
                {typeItem.Name}
              </MenuItem>
            ))}
          </Select>
          <InputLabel id="demo-simple-select-required-label">
            Fuel agreement
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            label="Fuel agreement"
            sx={{ width: '40%' }}
            value={fuelAgreement}
            onChange={(e) => setFuelAgreement(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {fuelAgreementData?.map((typeItem, index) => (
              <MenuItem key={index} value={typeItem.Id}>
                {typeItem.Name}
              </MenuItem>
            ))}
          </Select>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="First day in traffic"
              value={value}
              onChange={(newValue: Date | null) => {
                setValue(newValue);
              }}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <TextField
            label="Taxi number"
            id="outlined-size-normal"
            value={vehicleData?.VehicleGuid}
          />
          <TextField label="Vehicle bonus" id="outlined-size-normal" />
          <TextField label="Exemption fee:" id="outlined-size-normal" />
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            value={vehicleData?.VehicleGuid}
            style={{ width: 200 }}
          />
        </div>
      </Box>
    </>
  );
};

export default EditVehicle;
