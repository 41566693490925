import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'store';
import useBaseLinks, { LinksMap } from 'store/Auth/Links/useBaseLinks';
import { parseTemplate } from 'url-template';

type Return = {
  baseLink: string;
  getLink: (rel: string) => string | null;
  map: LinksMap | undefined;
};

const useAnalyticsLinks = (): Return => {
  const { linksMap: map, dates } = useSelector((state) => state.analytics);
  const { linksMap: facilityLinksMap } = useSelector(
    (state) => state.facilityAnalytics,
  );

  const { getLink: getBaseLink } = useBaseLinks();

  const baseLinkTemplate = getBaseLink('analytics');
  const [baseLink, setBaseLink] = useState('');
  useEffect(() => {
    /**
     * Effect for updating the base analytics link,
     * based on the base "analytics" template,
     * and the dates for filtering
     */
    if (baseLinkTemplate) {
      const { from, to } = dates;

      const urlTemplate = parseTemplate(decodeURI(baseLinkTemplate));
      setBaseLink(
        urlTemplate.expand({
          datefrom: format(new Date(from), 'yyyy-MM-dd'),
          dateto: format(new Date(to), 'yyyy-MM-dd'),
        }),
      );
    }
  }, [baseLinkTemplate, dates]);

  /**
   * Gets link with the given rel for analytics
   * Returns link from facilityLinksMap if there is any,
   * otherwise it returns link from analytics
   *
   * This is made this way because if there is a facility selected,
   * then we should see analytics for that facility instead of for all facilities
   */
  const getLink = useCallback(
    (rel: string) => {
      let href: string | null = null;
      if (facilityLinksMap) {
        href = facilityLinksMap[rel] ?? null;
      }
      if (!href && !!map) {
        href = map[rel] ?? null;
      }
      return href;
    },
    [map, facilityLinksMap],
  );

  return {
    baseLink,
    getLink,
    map,
  };
};

export default useAnalyticsLinks;
