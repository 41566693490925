import { FC, ReactNode } from 'react';
import { Typography, DialogActions, Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { TranslatableString } from 'i18n/translations/no';

interface Props {
  title?: string | TranslatableString;
  text?: string | ReactNode;
  children?: ReactNode | ReactNode[];
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: FC<Props> = ({
  title,
  text,
  children,
  onClose,
  onConfirm,
}): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} title={title}>
      {!!text && (
        <Typography
          variant="subtitle1"
          sx={{
            p: 4,
            textAlign: 'center',
            fontSize: `${theme.typography.pxToRem(15)}`,
          }}
          gutterBottom
          component="h3"
        >
          {text}
        </Typography>
      )}

      {!!children && children}

      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose}>
          {t('No')}
        </Button>
        <Button variant="contained" autoFocus onClick={onConfirm}>
          {t('Yes')}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default ConfirmationModal;
