export interface PureLightThemeMargins {
  none: number;
  xTiny: number;
  tiny: number;
  xSmall: number;
  small: number;
}
export const margins: PureLightThemeMargins = {
  none: 0,
  xTiny: 0.6,
  tiny: 2,
  xSmall: 3,
  small: 5,
};
