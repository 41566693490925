import { SearchBoxItem } from 'components/_shared/Search/SearchBox.Item';
import { SearchHistoryItemShape } from 'models/Search';
import { FC, ReactNode } from 'react';

type Props = {
  data: SearchHistoryItemShape;
  handleSelect: (item: SearchHistoryItemShape) => void;
  icon: ReactNode;
  actions?: ReactNode;
};

const SearchHistoryItem: FC<Props> = ({
  data,
  handleSelect,
  icon,
  actions,
}) => {
  return (
    <SearchBoxItem
      text={data.SearchText}
      selectQuery={() => handleSelect(data)}
      icon={icon}
      actions={actions}
    />
  );
};

export default SearchHistoryItem;
