import { FC, ReactNode, useState, useContext } from 'react';
import clsx from 'clsx';
import { SidebarContext } from 'contexts/SidebarContext';
import { NavLink as RouterLink } from 'react-router-dom';

import {
  Button,
  Tooltip,
  Badge,
  Collapse,
  ListItem,
  TooltipProps,
  tooltipClasses,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  open?: boolean;
  active?: boolean;
  onClick?: () => void;
  name:
    | 'Dashboard'
    | 'Analytics'
    | 'Financial'
    | 'Monitoring'
    | 'Camera feed'
    | 'Facilities'
    | 'Memberships'
    | 'Pricing'
    | 'Matching'
    | 'Parking sessions'
    | 'Facility access'
    | 'Event log'
    | 'Subscriptions'
    | 'Customers'
    | 'End-Users'
    | 'Team'
    | 'FAQ'
    | 'Support'
    | 'Documentation'
    | 'Operators'
    | 'ID-carriers'
    | 'System'
    | 'Users'
    | 'SubOperators'
    | 'Vehicle groups'
    | 'Logout'
    | 'Flagged parkings';
}

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  open: openParent,
  active,
  name,
  onClick,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent ?? false);
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === '' ? (
                <Badge color="primary" variant="dot" />
              ) : (
                <Badge badgeContent={badge} />
              )}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest} onClick={onClick}>
      <Button
        className={active ? 'Mui-active' : ''}
        disableRipple
        component={onClick ? 'div' : RouterLink}
        onClick={closeSidebar}
        to={link ?? ''}
        startIcon={Icon}
      >
        {t(name)}
        {badgeTooltip ? (
          <TooltipWrapper title={badgeTooltip} arrow placement="right">
            {badge === '' ? (
              <Badge color="primary" variant="dot" />
            ) : (
              <Badge badgeContent={badge} />
            )}
          </TooltipWrapper>
        ) : badge === '' ? (
          <Badge color="primary" variant="dot" />
        ) : (
          <Badge badgeContent={badge} />
        )}
      </Button>
    </ListItem>
  );
};

export default SidebarMenuItem;
