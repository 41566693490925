import { FC } from 'react';
import { Alert, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ErrorLoginPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Alert severity="error">
        {t(
          'We cannot connect to the backend at this point. We apologize for the inconvenience',
        )}
      </Alert>
    </Container>
  );
};

export default ErrorLoginPage;
