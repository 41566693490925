import { FC, useState, useEffect } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import Api from 'Api';
import { useGetWhitelistedUsersQuery } from 'store/_Api/Users';
import { WhitelistedUser } from 'models/EndUsers';
import EmptyTable from 'atoms/components/EmptyTable';

type Props = {
  whiteListedUsersLink: string;
};

const WhitelistedUsersTab: FC<Props> = ({ whiteListedUsersLink }) => {
  const { t } = useTranslation();

  const { data, refetch } = useGetWhitelistedUsersQuery(whiteListedUsersLink, {
    skip: !whiteListedUsersLink,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [whiteListedUsersLink]);

  const handleWhitelistAction = async (
    user: WhitelistedUser,
    action: 'add' | 'remove',
  ) => {
    try {
      const actionLink = getApiHrefByRel(
        user.Links,
        action === 'add' ? 'add-user' : 'remove',
      );
      if (actionLink) {
        if (action == 'add') {
          await Api.post(actionLink);
        }
        if (action == 'remove') {
          await Api.delete(actionLink);
        }
        refetch();

        // Refetch data to update the lists
      }
    } catch (error) {
      console.error(
        `Error ${action === 'add' ? 'whitelisting' : 'removing'}:`,
        error,
      );
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card sx={{ mb: 2 }}>
        {data && data.Items.length === 0 ? (
          <EmptyTable />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Email')}</TableCell>
                  <TableCell align="center">{t('Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.Items.map((user) => (
                    <TableRow key={user.UserGuid}>
                      <TableCell>{user.FullName}</TableCell>
                      <TableCell>{user.Email}</TableCell>
                      <TableCell align="center">
                        {user.Links.some((link) => link.Rel === 'remove') ? (
                          user.UserGuid ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                handleWhitelistAction(user, 'remove')
                              }
                            >
                              {t('Remove')}
                            </Button>
                          ) : null
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleWhitelistAction(user, 'add')}
                          >
                            Add
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
      {/**
       * Commented out, because it's not usable yet
       * 
import { useBaseLinks } from 'store/Auth';
         import EndUserList from 'components/EndUser/List';
  const [userToWhitelist, setUserToWhitelist] = useState<
    EndUserShape | undefined
  >(undefined);
  const [endUsersList, setEndUsersList] = useState<EndUserShape[]>([]);
  const [hasNextLink, setHasNextLink] = useState(true);
  const [filterLink, setFilterLink] = useState('');
  useEffect(() => {
    if (filterLink) {
      setLink(filterLink);
      return;
    }
    setLink(usersLink);
  }, [usersLink, filterLink]);
  const getFilteredData = async (query?: string) => {
    console.log('filter');

    if (query && listActionLinks) {
      const filterHref = getApiHrefByRel(listActionLinks, 'filter');
      if (filterHref) {
        const updatedLink = parseTemplate(decodeURI(filterHref)).expand({
          q: query,
        });

        setFilterLink(updatedLink);
        return;
      }
    }
    setFilterLink('');
  };
const { getLink } = useBaseLinks();
  const onScroll = () => {
    if (data) {
      const nextLink = getApiHrefByRel(data.Links, 'next');
      if (nextLink) {
        setLink(nextLink);
      } else {
        setHasNextLink(false);
      }
    }
  };
    const customerList = customers?.Items;
  const usersLink = getLink('end-users') ?? getLink('customers') ?? '';

  const [link, setLink] = useState('');

  const { data: customers } = useGetTableDataQuery(link, { skip: !link });
  
  const listActionLinks = data?.Links;

  useEffect(() => {
    if (customerList) {
      if (filterLink) {
        setEndUsersList(customerList);
        return;
      } else if (link !== usersLink) {
        setEndUsersList((prevState) => [...prevState, ...customerList]);
        return;
      } else {
        setEndUsersList(customerList);
      }
    }
  }, [customerList, filterLink, link, usersLink]);
      <EndUserList
        list={endUsersList}
        loadMoreCustomer={onScroll}
        getFilteredData={getFilteredData}
        hasMore={hasNextLink}
        setUserToWhitelist={setUserToWhitelist}
      />
       */}
    </>
  );
};

export default WhitelistedUsersTab;
