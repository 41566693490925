import { Button, Grid, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useGetCarParkAreasQuery } from 'store/_Api/Facilities';
import AddCarParks from '../AddCarParks';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from 'atoms/Icons';
type Props = {
  carParksLink: string;
};
const CarParkTab: FC<Props> = ({ carParksLink }) => {
  const { data: carParksData } = useGetCarParkAreasQuery(carParksLink, {
    skip: !carParksLink,
  });
  const theme = useTheme();
  const { t } = useTranslation();
  const [showAddCarParkModel, setShowAddCarParkModel] = useState(false);

  return (
    <Grid>
      <Grid container display={'flex'} flexDirection={'column'} px={25} py={2}>
        <Grid display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h3" color="text.secondary">
            {t('Parking areas')}
          </Typography>
          <Button
            variant="contained"
            style={{ lineHeight: '0.75' }}
            startIcon={
              <PlusIcon
                fill={theme.colors.alpha.white[100]}
                opacity={1}
                height={15}
              />
            }
            onClick={() => setShowAddCarParkModel(true)}
          >
            {t('Add new')}
          </Button>
        </Grid>
        {carParksData?.Items.map((carPark) => (
          <Typography variant="h4" py={2} key={carPark?.FacilityGuid}>
            {carPark?.CarParkName}
          </Typography>
        ))}
      </Grid>

      {showAddCarParkModel && (
        <AddCarParks
          handleClose={() => setShowAddCarParkModel(false)}
          addCarParksLink={
            getApiHrefByRel(carParksData?.Links || [], 'add-car-park') || ''
          }
        />
      )}
    </Grid>
  );
};

export default CarParkTab;
