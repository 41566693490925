import { LinkShape } from 'models/Link';
import SuspenseLoader from 'components/_shared/SuspenseLoader';
import {
  ContractShape,
  EndUserShape,
  SubscriptionShape,
  TransactionShape,
  VehicleShape,
} from 'models/EndUsers';
import { SearchResultType } from 'models/Search';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import ContractSearchResults from './Item/ContractSearchResults';
import EndUserSearchResults from './Item/EndUserSearchResults';
import SubscriptionSearchResults from './Item/SubscriptionSearchResults';
import TransactionSearchResults from './Item/TransactionSearchResults';
import VehicleSearchResults from './Item/VehicleSearchResults';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'components/_shared/Scrollbar';

import './SearchResults.scss';

export type SearchResultsShape = {
  [key in SearchResultType]:
    | EndUserShape[]
    | TransactionShape[]
    | SubscriptionShape[]
    | VehicleShape[]
    | ContractShape[]
    | null;
};

export type SearchResultLinksShape = {
  users: string | null;
  customers: string | null;
  transactions: string | null;
  subscriptions: string | null;
  vehicles: string | null;
  contracts: string | null;
};

type Props = {
  links: LinkShape[];
  searchQuery: string;
};

const getTypeFromRel = (rel: string): string => {
  return rel.replace('search-', '');
};

const SearchResults: FC<Props> = ({ links, searchQuery }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [hasAnyResults, setHasAnyResults] = useState<undefined | boolean>();

  const initialSectionsState = useMemo(() => {
    const types = links.map((link) => getTypeFromRel(link.Rel));
    const state: {
      [key: string]: { isLoading: boolean; hasResults: undefined | boolean };
    } = {};
    types.forEach((type) => {
      state[type] = {
        isLoading: true,
        hasResults: undefined,
      };
    });

    return state;
  }, [links]);

  const [sectionsState, setSectionsState] =
    useState<typeof initialSectionsState>(initialSectionsState);

  useEffect(
    () => setSectionsState({ ...initialSectionsState }),
    [initialSectionsState],
  );

  const updateSectionState = (
    type: string,
    isLoading: boolean,
    hasResults: boolean | undefined,
  ) => {
    setTimeout(
      () =>
        setSectionsState((prev) => ({
          ...prev,
          [type]: {
            isLoading,
            hasResults,
          },
        })),
      10,
    );
  };

  useEffect(() => {
    const values = Object.values(sectionsState);
    const withLoading = values.filter((value) => value.isLoading === true);
    const withResults = values.filter((value) => value.hasResults === true);

    setIsLoading(withLoading.length > 0);
    setHasAnyResults(withResults.length > 0);
  }, [sectionsState]);

  const result = useMemo(() => {
    const getResults = (type: string, link: LinkShape): JSX.Element => {
      switch (type) {
        case 'customers':
        case 'users':
          return (
            <EndUserSearchResults
              searchUrl={link.Href}
              type={type}
              setSectionState={(isLoading, hasResults) =>
                updateSectionState(type, isLoading, hasResults)
              }
            />
          );

        case 'contracts':
          return (
            <ContractSearchResults
              searchUrl={link.Href}
              setSectionState={(isLoading, hasResults) =>
                updateSectionState(type, isLoading, hasResults)
              }
            />
          );

        case 'subscriptions':
          return (
            <SubscriptionSearchResults
              searchUrl={link.Href}
              setSectionState={(isLoading, hasResults) =>
                updateSectionState(type, isLoading, hasResults)
              }
            />
          );

        case 'transactions':
          return (
            <TransactionSearchResults
              searchUrl={link.Href}
              setSectionState={(isLoading, hasResults) =>
                updateSectionState(type, isLoading, hasResults)
              }
            />
          );

        case 'vehicles':
          return (
            <VehicleSearchResults
              searchUrl={link.Href}
              setSectionState={(isLoading, hasResults) =>
                updateSectionState(type, isLoading, hasResults)
              }
            />
          );

        default:
          return <></>;
      }
    };

    return links.map((link) => {
      const type = getTypeFromRel(link.Rel);
      return <Fragment key={type}>{getResults(type, link)}</Fragment>;
    });
  }, [links]);

  return (
    <div className="search-results">
      <Scrollbar autoHeight={true} autoHeightMin={70} autoHeightMax={800}>
        {isLoading && <SuspenseLoader isFullScreen={false} />}
        <div
          className={
            'search-results-wrapper' + (isLoading ? ' is-loading' : '')
          }
        >
          {result}
          {!hasAnyResults && (
            <p className="search-results-error-message">
              {t('No results for')} {`"${searchQuery}"`}
            </p>
          )}
        </div>
      </Scrollbar>
    </div>
  );
};

export default SearchResults;
