/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Suspense } from 'react';

const Loader =
  (Component: any) =>
  (props: any): JSX.Element =>
    (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );

export default Loader;
