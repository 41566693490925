import { LinkShape } from 'models/Link';
import { Facility } from 'models/Facilities/Facility';
import { FacilityArea } from 'models/Facilities/FacilityArea';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { startOfWeek } from 'date-fns';

export type PeriodType =
  | ''
  | 'today'
  | 'yesterday'
  | 'this-week'
  | 'last-week'
  | 'this-month'
  | 'last-month'
  | 'absolute-period'
  | 'all-time';

export type FacilityPlainItem = {
  FacilityGuid: string;
  Name: string;
  Links: LinkShape[];
};

export type FacilityMapShape = {
  [key: string]: FacilityPlainItem;
} | null;

interface FacilitiesState {
  facilities: Facility[];
  facilitiesAreas: FacilityArea[];
  selectedFacility: string | undefined;
  startDate: string;
  endDate: string;
  period: PeriodType;
  showSelectedDateRange: boolean;
  links: LinkShape[] | undefined;
  map: FacilityMapShape;
  list: FacilityPlainItem[];
}

const initialState: FacilitiesState = {
  facilities: [],
  facilitiesAreas: [],
  selectedFacility: undefined,
  startDate: startOfWeek(new Date(), { weekStartsOn: 1 }).toString(),
  endDate: new Date().toString(),
  period: 'this-week',
  showSelectedDateRange: true,
  links: undefined,
  map: null,
  list: [],
};

const slice = createSlice({
  name: 'facilities',
  initialState,
  reducers: {
    saveFacilities(
      state: FacilitiesState,
      action: PayloadAction<{ facilities: Facility[] }>,
    ) {
      state.facilities = action.payload.facilities;
    },
    saveSelectedFacilityAreas(
      state: FacilitiesState,
      action: PayloadAction<{ facilitiesAreas: FacilityArea[] }>,
    ) {
      state.facilitiesAreas = action.payload.facilitiesAreas;
    },
    saveSelectedFacility(
      state: FacilitiesState,
      action: PayloadAction<{ selectedFacility: string | undefined }>,
    ) {
      state.selectedFacility = action.payload.selectedFacility;
    },

    saveSelectedEventDates(
      state: FacilitiesState,
      action: PayloadAction<{ startDate: string; endDate: string }>,
    ) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    savePeriod(
      state: FacilitiesState,
      action: PayloadAction<{ period: PeriodType }>,
    ) {
      state.period = action.payload.period;
    },
    saveLinks(
      state: FacilitiesState,
      action: PayloadAction<{ links: LinkShape[] | undefined }>,
    ) {
      state.links = action.payload.links;
    },
    saveShowSelectedDateRange(
      state: FacilitiesState,
      action: PayloadAction<{ showSelectedDateRange: boolean }>,
    ) {
      state.showSelectedDateRange = action.payload.showSelectedDateRange;
    },
    setFacilityMap(
      state: FacilitiesState,
      action: PayloadAction<FacilityMapShape>,
    ) {
      state.map = action.payload;
    },
    setFacilityList(
      state: FacilitiesState,
      action: PayloadAction<FacilityPlainItem[]>,
    ) {
      state.list = action.payload;
    },
  },
});

export const {
  saveFacilities,
  saveSelectedFacilityAreas,
  saveSelectedFacility,
  saveSelectedEventDates,
  savePeriod,
  saveLinks,
  saveShowSelectedDateRange,
  setFacilityMap,
  setFacilityList,
} = slice.actions;

export const FacilitiesReducer = slice.reducer;
