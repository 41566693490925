import { useState, useEffect, FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Divider,
  useTheme,
  Button,
} from '@mui/material';
import { SubscriptionShape } from 'models/EndUsers';
import { useTranslation } from 'react-i18next';
import EmptyTable from 'atoms/components/EmptyTable';
import { formatTimeDate } from '_utils/date';
import InfiniteScroll from 'react-infinite-scroll-component';
import SuspenseLoader from 'components/_shared/SuspenseLoader';
import StyledTableCell from 'atoms/components/StyledTableCell';

type Props = {
  list: SubscriptionShape[];
  handleFetchList: () => void;
};

const EndUserSubscriptionList: FC<Props> = ({
  list,
  handleFetchList,
}): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const success = {
    '&:hover': {
      background: theme.colors.success.lighter,
    },
    background: theme.colors.success.lighter,
    color: theme.palette.success.main,
  };
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionShape[]>(
    [],
  );

  useEffect(() => {
    setSubscriptionList(list);
  }, [list]);

  return (
    <>
      {subscriptionList.length === 0 ? (
        <EmptyTable />
      ) : (
        <>
          <TableContainer>
            <InfiniteScroll
              dataLength={subscriptionList.length}
              next={handleFetchList}
              hasMore={true}
              loader={
                <div
                  style={{
                    position: 'absolute',
                    marginLeft: '50%',
                    height: '60px',
                  }}
                >
                  {list.length !== subscriptionList.length && (
                    <SuspenseLoader />
                  )}
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Operator')}</TableCell>
                    <TableCell>{t('Subscription package')}</TableCell>
                    <TableCell>{t('Vehicles')}</TableCell>
                    <TableCell>{t('Subscription period')}</TableCell>
                    <TableCell>{t('Subscription code')}</TableCell>
                    <TableCell>{t('Price')}</TableCell>
                    <TableCell>{t('Status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionList?.map((item, index) => {
                    return (
                      <TableRow hover key={index}>
                        <StyledTableCell title={item.Operator} />
                        <StyledTableCell title={item.SubscriptionPackage} />
                        <StyledTableCell title={item.Vehicles[0]} />
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            sx={{ width: '100px' }}
                          >
                            {item.SubscriptionPeriod &&
                              formatTimeDate(item.SubscriptionPeriod.Start)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            sx={{ width: '100px' }}
                          >
                            {item.SubscriptionPeriod &&
                              formatTimeDate(item.SubscriptionPeriod.End)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            sx={{ width: '20px' }}
                          >
                            {item.Price}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button variant="contained" sx={success}>
                            {item.Status}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
          <Divider />
        </>
      )}
    </>
  );
};

export default EndUserSubscriptionList;
