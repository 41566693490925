import { SearchHistoryItemShape } from 'models/Search';
import { FC } from 'react';
import RecentSearches from './Recent';
import SavedSearches from './Saved';

type Props = {
  handleSelectedItem: (item: SearchHistoryItemShape) => void;
};

const SearchHistory: FC<Props> = ({ handleSelectedItem }) => {
  return (
    <>
      <RecentSearches handleSelectItem={handleSelectedItem} />
      <SavedSearches handleSelectItem={handleSelectedItem} />
    </>
  );
};

export default SearchHistory;
