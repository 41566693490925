import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'hooks/useScrollTop';

import App from 'App';
import { SidebarProvider } from 'contexts/SidebarContext';
import * as serviceWorker from 'serviceWorker';
// import { AuthProvider } from "contexts/AuthContext";
import { Provider } from 'react-redux';
import store from 'store';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <SidebarProvider>
      <BrowserRouter>
        <ScrollTop />
        {/* <AuthProvider> */}
        <App />
        {/* </AuthProvider> */}
      </BrowserRouter>
    </SidebarProvider>
  </Provider>,
);

serviceWorker.unregister();
