import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LogoutState = {
  showConfirmation: boolean;
};

const initialState: LogoutState = {
  showConfirmation: false,
};

const slice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    setShowConfirmation(state: LogoutState, action: PayloadAction<boolean>) {
      state.showConfirmation = action.payload;
    },
  },
});

export const { setShowConfirmation } = slice.actions;

export const LogoutReducer = slice.reducer;
