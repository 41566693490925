import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import CustomDialog from 'components/_shared/Dialog/dialog';
import { TransactionShape } from 'models/EndUsers';

interface Props {
  transaction: TransactionShape;
  handleClose: () => void;
}

const CustomerTransactionModal = ({
  transaction,
  handleClose,
}: Props): JSX.Element => {
  return (
    <CustomDialog
      header={
        <Typography variant="h3" color="text.secondary">
          Customer Transaction - {transaction.Operator}
        </Typography>
      }
      onClose={handleClose}
      title={transaction.Operator}
    >
      <Grid
        sx={{
          px: 4,
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item md={6} xs={12}>
          <Card>
            <Box py={2} px={3} textAlign="center">
              <Typography variant="h4" gutterBottom>
                Transaction Detail
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  TransactionGuid
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.TransactionGuid}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  Operator
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.Operator}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  Facility
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.Facility}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  CarPark
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.CarPark}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  Started
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.Started.toString()}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  Ended
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.Ended.toString()}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  Price
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.Price}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  LicensePlate
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.LicensePlate}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  ParkingAttribute
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {transaction.ParkingAttribute}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default CustomerTransactionModal;
