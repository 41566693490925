import { Box, CircularProgress, Dialog, Divider, styled } from '@mui/material';
import { getSearchLink } from 'Api/ApiCommonFunctions';
import { SearchHistoryItemShape } from 'models/Search';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { useBaseLinks } from 'store/Auth';
import { setIsOpened as setIsSearchOpened } from 'store/slices/Search';
import { useGetSearchLinksQuery } from 'store/_Api/Search';
import SearchForm from './Form';
import SearchHistory from './History';
import SearchResults from './Results/SearchResults';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { LinkShape } from 'models/Link';
import SuspenseLoader from 'components/_shared/SuspenseLoader';

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px);
        max-width: 800px;
    }
`,
);

export const SearchBoxDialog = (): JSX.Element => {
  const { isOpened } = useSelector((state) => state.Search);
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>('');

  const [hasSearched, setHasSearched] = useState(false);

  const { getLink } = useBaseLinks();
  const searchUrlTemplate = getLink('search') || '';
  const [mainSearchUrl, setMainSearchUrl] = useState<string | undefined>();

  const [saveLink, setSaveLink] = useState<string | undefined>(undefined);
  const [unSaveLink, setUnSaveLink] = useState<string | undefined>(undefined);

  const { data: linksResponse, isLoading } = useGetSearchLinksQuery(
    mainSearchUrl ?? skipToken,
  );
  const [searchLinks, setSearchLinks] = useState<LinkShape[]>([]);

  useEffect(() => {
    const allSearchLinks = linksResponse?.Links ?? [];
    const queryLinks: LinkShape[] = [];
    allSearchLinks.forEach((link) => {
      switch (link.Rel) {
        case 'self':
          return;

        case 'unsave-search':
          setUnSaveLink(link.Href);
          return;

        case 'save-search':
          setSaveLink(link.Href);
          return;

        default:
          queryLinks.push(link);
      }
    });

    setSearchLinks(queryLinks);
  }, [linksResponse]);

  const search = (thisQuery?: string) => {
    const needle = thisQuery ?? query;
    if (searchUrlTemplate && needle) {
      setQuery(needle);
      setMainSearchUrl(getSearchLink(searchUrlTemplate, needle) ?? undefined);
      setSearchLinks([]);
      setHasSearched(true);
    }
  };

  const handleSelectHistoryItem = (item: SearchHistoryItemShape): void => {
    search(item.SearchText);
  };
  return (
    <DialogWrapper
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => {
        setQuery('');
        dispatch(setIsSearchOpened(false));
        setHasSearched(false);
      }}
      open={isOpened}
    >
      <SearchForm
        search={search}
        query={query}
        setQuery={setQuery}
        setHasSearched={setHasSearched}
        saveSearchLink={saveLink}
        unSaveSearchLink={unSaveLink}
      />
      <Divider />
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 5,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            height: 'auto',
            pl: 1.2,
          }}
        >
          {hasSearched ? (
            <>
              {isLoading || !searchLinks.length ? (
                <div style={{ height: '70px', marginTop: '10px' }}>
                  <SuspenseLoader isFullScreen={false} />
                </div>
              ) : (
                <SearchResults links={searchLinks} searchQuery={query} />
              )}
            </>
          ) : (
            <SearchHistory handleSelectedItem={handleSelectHistoryItem} />
          )}
        </Box>
      )}
    </DialogWrapper>
  );
};
