import {
  Grid,
  InputAdornment,
  InputBase,
  Button,
  Box,
  useTheme,
  IconButton,
} from '@mui/material';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';

import { EmptyStarIcon, FilledStarIcon, SearchIcon } from '../SearchBox.Icons';
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Api from 'Api';

type Props = {
  search: () => void;
  query: string;
  setQuery: (query: string) => void;
  setHasSearched: (hasSearched: boolean) => void;
  saveSearchLink?: string;
  unSaveSearchLink?: string;
};

const SearchForm: FC<Props> = ({
  search,
  query,
  setQuery,
  setHasSearched,
  saveSearchLink,
  unSaveSearchLink,
}) => {
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const [isSaved, setIsSaved] = useState<boolean>(!!saveSearchLink);

  useEffect(() => setIsSaved(!!saveSearchLink), [saveSearchLink]);

  const handleSaveOrUnsave = async () => {
    const link = saveSearchLink ?? unSaveSearchLink;
    if (link) {
      await Api.post(link);
      setIsSaved(!isSaved);
    }
  };
  return (
    <Box sx={{ pl: 2, pr: 2 }}>
      <Formik
        initialValues={{ search: query }}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit={async (_values): Promise<void> => {
          search();
        }}
      >
        <Form placeholder={undefined}>
          <Grid
            flexDirection="row"
            xs={12}
            md={12}
            lg={12}
            display="flex"
            item
            paddingBottom={theme.margins.tiny}
            paddingTop={theme.margins.tiny}
          >
            <InputBase
              placeholder={t('Search here')}
              autoFocus
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" sx={{ mr: 1 }} color="primary" />
                </InputAdornment>
              }
              fullWidth
              endAdornment={
                query && (
                  <>
                    <IconButton onClick={handleSaveOrUnsave} color="primary">
                      {isSaved ? <FilledStarIcon /> : <EmptyStarIcon />}
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        setQuery('');
                        setHasSearched(false);
                      }}
                      color="primary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )
              }
            />

            <Button
              size="small"
              variant="contained"
              disabled={query.length > 3 ? false : true}
              onClick={() => search()}
            >
              Search
            </Button>
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
};

export default SearchForm;
