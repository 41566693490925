import { Avatar, Box, Typography, useTheme } from '@mui/material';
import useAuth from 'store/Auth/useAuth';

const SidebarTopSection = (): JSX.Element => {
  const theme = useTheme();
  const { profile: user } = useAuth();

  return (
    <>
      {!!user && (
        <Box sx={{ textAlign: 'center', mx: 2, pt: 1, position: 'relative' }}>
          <Avatar
            sx={{ width: 68, height: 68, mb: 2, mx: 'auto' }}
            alt={user.CompanyName ?? ''}
            src=""
          />
          <Typography
            variant="h4"
            sx={{ color: `${theme.colors.alpha.trueWhite[100]}` }}
          >
            {user.CompanyName}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: `${theme.colors.alpha.trueWhite[70]}` }}
          >
            {user.Name}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SidebarTopSection;
