import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AnalyticsOperator } from 'models/Analytics/Operator';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useBaseLinks } from 'store/Auth';

import { useGetOperatorsLinkQuery } from 'store/_Api/Operators';

type Return = {
  selected: undefined | AnalyticsOperator;
  select: (
    operator: undefined | AnalyticsOperator,
    withReload?: boolean,
  ) => void;
};

const getFromLocalStorage = () => {
  const dirty = localStorage.getItem('operator');
  if (!dirty) {
    return undefined;
  }
  return JSON.parse(dirty) as AnalyticsOperator;
};

const useSelectedOperator = (): Return => {
  const [selected, setSelected] = useState<undefined | AnalyticsOperator>();
  const { setLinksForOperator } = useBaseLinks();

  const hasSetFromLocalStorage = useRef(false);
  const hasSetLinkOfSelected = useRef(false);
  const [operatorLink, setOperatorLink] = useState<undefined | string>();

  const { data: operatorData } = useGetOperatorsLinkQuery(
    operatorLink ?? skipToken,
  );

  /**
   * Handles selction of an operator or clearing of selected operator when it's undefined
   */
  const select = useCallback(
    (operator: undefined | AnalyticsOperator, withReload = false) => {
      // first we update internal state
      setSelected(operator);

      /**
       * We remove operator from localstorage and analytics link,
       * to clear old data
       */
      localStorage.removeItem('operator');

      if (!operator) {
        /**
         * If operator is undefined,
         * there's nothing more to do here
         */
        setOperatorLink(undefined);
        return;
      }
      // if there is an operator,
      // we first set it in localStorage
      localStorage.setItem('operator', JSON.stringify(operator));
      if (withReload) {
        window.location.reload();
      } else {
        // then we find its analytics link
        const selfOperatorLink = operator.Links.find(
          (link) => link.Rel === 'self',
        );
        if (selfOperatorLink) {
          // Then we set that in BaseLinks if analyticsLink exists
          setOperatorLink(selfOperatorLink?.Href);
          hasSetLinkOfSelected.current = false;
        }
      }
    },
    [],
  );

  /**
   * Selecting the operator to the one set in localStorage, if there is any
   */
  useEffect(() => {
    if (hasSetFromLocalStorage.current) {
      /**
       * We prevent potential infinite selecting, if hasSetFromLocalStorage is true
       */
      return;
    }
    const storedOperator = getFromLocalStorage();
    if (storedOperator) {
      select(storedOperator);
      hasSetFromLocalStorage.current = true;
    }
  }, [select]);

  useEffect(() => {
    if (!selected) {
      setLinksForOperator(undefined);
    } else {
      if (operatorData) {
        if (hasSetLinkOfSelected.current === false) {
          setLinksForOperator(operatorData.Links ?? []);
          hasSetLinkOfSelected.current = true;
        }
      }
    }
  }, [operatorData, selected, setLinksForOperator]);

  return {
    selected,
    select,
  };
};

export default useSelectedOperator;
