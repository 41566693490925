import Guest from 'components/_shared/Guest';
import { Outlet, RouteObject } from 'react-router-dom';
import loginRouter from './Login';
import passwordResetRouter from './PasswordReset';

const guestRouter: RouteObject = {
  element: (
    <Guest>
      <Outlet />
    </Guest>
  ),
  children: [...loginRouter, ...passwordResetRouter],
};

export default guestRouter;
