import { createApi } from '@reduxjs/toolkit/query/react';
import {
  RelayConnectionFormModel,
  RelayShape,
  RelaysApiResponse,
  RelaysFormModel,
} from 'models/Facilities';
import { baseQuery } from '../..';
import {
  AddExitLaneModel,
  LaneApiResponse,
} from '../../../../models/Facilities';

export type PostType = {
  url: string;
  payload: RelaysFormModel | AddExitLaneModel | RelayConnectionFormModel;
};

export const RelaysApi = createApi({
  reducerPath: 'relays',
  tagTypes: ['relays', 'relay', 'entry-lanes', 'exit-lane'],
  baseQuery,
  endpoints: (builder) => ({
    getRelays: builder.query<RelaysApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['relays'],
    }),
    getRelay: builder.query<RelayShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['relays'],
    }),
    getLanes: builder.query<LaneApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['entry-lanes', 'exit-lane'],
    }),
    registerRelay: builder.mutation<RelaysApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['relays'],
    }),
    updateRelayConnection: builder.mutation<RelaysApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['relays'],
    }),
    addEntryExitLane: builder.mutation<LaneApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['entry-lanes', 'exit-lane'],
    }),
    assignRelayToLane: builder.mutation<LaneApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['entry-lanes', 'exit-lane'],
    }),
    updateLaneNotes: builder.mutation<LaneApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['entry-lanes', 'exit-lane'],
    }),
  }),
});

export const {
  useGetRelaysQuery,
  useGetRelayQuery,
  useGetLanesQuery,
  useRegisterRelayMutation,
  useAddEntryExitLaneMutation,
  useAssignRelayToLaneMutation,
  useUpdateLaneNotesMutation,
  useUpdateRelayConnectionMutation,
} = RelaysApi;
