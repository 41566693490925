/**
 * FormatDate will format input date and return in date format of 1-1-2022.
 */

import { format } from 'date-fns';
import moment from 'moment';

export const formatDate = (date: string): string => {
  const selectedDate = new Date(date);
  const mm = selectedDate.getMonth() + 1;
  const dd = selectedDate.getDate();
  const yyyy = selectedDate.getFullYear();

  return `${dd}-${mm}-${yyyy}`;
};

export const formatTimeDate = (dateTime: Date): string => {
  const modifiedDateTime = dateTime
    .toString()
    .replace('T', ' ')
    .replace('Z', ' ')
    .replaceAll('-', '.')
    .slice(0, -4);

  return modifiedDateTime;
};

// * Formats a date string into a more readable format.
export const prettifyDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleString('en-US', options);
};
/**
 * Calculates time difference in user friendly format. for example: 10h 12m
 */
export function getTimeDifference(startTime: string, endTime: string): string {
  try {
    /**
     * Time difference in miliseconds
     */
    const msDiff = new Date(endTime).getTime() - new Date(startTime).getTime();
    /**
     * Time difference in minutes
     */
    const mDiff = Math.floor(msDiff / 1000 / 60);

    const hours = Math.floor(mDiff / 60);
    const minutes = mDiff - hours * 60;

    if (!!hours && !!minutes) {
      return `${hours}h ${minutes}m`;
    }

    if (!!hours && !minutes) {
      return `${hours}h`;
    }

    return `${minutes}m`;
  } catch (e) {
    console.error('Error when calculating time diff:', e);
    return 'n/a';
  }
}

export const getFormattedTzDiff = (timeZone: string): string => {
  const offsetInMinutes = moment().tz(timeZone).utcOffset();
  const isPositive = offsetInMinutes > 0;
  const offsetHours = Math.floor(offsetInMinutes / 60);
  const offsetMinutes = offsetInMinutes - offsetHours * 60;
  const diff =
    (isPositive ? '+' : '-') +
    offsetHours.toString().padStart(2, '0') +
    ':' +
    offsetMinutes.toString().padStart(2, '0');
  return diff;
};

export const getDateTimeConvertedToUTC = (
  dateTime: string,
  dateType: 'start' | 'end',
  timeZone: string,
): string => {
  const tzDiff = getFormattedTzDiff(timeZone);

  const formatted = format(
    new Date(dateTime),
    "yyyy-MM-dd'T'HH:mm:ss" + tzDiff,
  );

  return (
    moment.parseZone(formatted).utc().format('yyyy-MM-DDTHH:mm') +
    (dateType === 'end' ? ':59' : ':00') +
    'Z'
  );
};
