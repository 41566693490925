import { TranslatableString } from './no';

const seJSON: { [key in TranslatableString]: string } = {
  //Languages
  'Language switcher': 'Språkväxling',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Finnish: 'Finska',
  Swedish: 'Svenska',

  //login
  'Sign in': 'Logga in',
  'Email address': 'E-postadress',
  Password: 'Kom ihåg mig',
  'Remember me': 'Remember me',
  'Lost password?': 'Glömt lösenord?',
  'The email provided should be a valid email address':
    'Den angivna e-postadressen bör vara en giltig e-postadress',
  'The email field is required': 'E-postfältet är obligatoriskt',
  'The password field is required': 'Lösenordsfältet är obligatoriskt',
  'Fill in the fields below to sign into your account.':
    'Fyll i fälten nedan för att logga in på ditt konto.',

  //Menu
  Overview: 'Översikt',
  Dashboard: 'instrumentbräda',
  Analytics: 'Analytics',
  Financial: 'Finansiell',
  Management: 'Förvaltning',
  Monitoring: 'Övervakning',
  'Camera feed': 'Kameraflöde',
  Facilities: 'Faciliteter',
  Pricing: 'Prissättning',
  Matching: 'Motsvarande',
  'Parking sessions': 'Parkeringstillfällen',
  Allowlist: 'Händelselogg',
  'Event log': 'Hendelsesloggen',
  Subscriptions: 'Prenumerationer',
  Customers: 'Kunder',
  'End-Users': 'Slutanvändare',
  Team: 'Team',
  Help: 'Hjälp',
  FAQ: 'FAQ',
  Support: 'Stöd',
  Documentation: 'Dokumentation',
  Configuration: 'Konfiguration',
  Operators: 'Operatörer',
  'ID-carriers': 'ID-bärare',
  System: 'Systemet',
  Users: 'Användare',
  SubOperators: 'Underoperatörer',
  Logout: 'Logga ut',

  'Search for license plate, user, transaction etc.':
    'Sök efter registreringsskylt, användare, transaktion etc.',

  'Search here': 'Search here',
  'Recent searches': 'Recent searches',
  'Saved searches': 'Saved searches',
  'User results': 'User results',
  'Licence plates & vehicles': 'Licence plates & vehicles',

  //Dashboard
  'All facilities': 'Alla faciliteter',
  Period: 'Period',
  Today: 'I dag',
  Yesterday: 'I går',
  'This week': 'Denna vecka',
  'Last week': 'Förra veckan',
  'This month': 'Den här månaden',
  'Last month': 'Förra månaden',
  'Custom period': 'Anpassad period',
  'All time': 'Hela tiden',
  'Packing facility': 'Packningsanläggning',
  Occupancy: 'Beläggning',
  'Facility overview': 'Anläggningsöversikt',
  'Facility area': 'Anläggningsområde',
  'In order to see the ocuppancy percent, you need to fill in the capacity field':
    'För att se beläggningsprocenten behöver du fylla i kapacitetsfältet',
  Revenue: 'Inkomst',
  Sales: 'Försäljning',
  up: 'upp',
  down: 'ner',
  View: 'Se',
  'n/a': 'n/a',

  //Analytics Page
  'Occupancy & sessions': 'Beläggning & sessioner',
  'Average occupancy': 'Genomsnittlig beläggning',
  'Average session': 'Genomsnittlig session',
  Sessions: 'Sessioner',
  'in total': 'totalt',
  weekly: 'varje vecka',
  daily: 'dagligen',
  'per hour': 'per timme',
  'Compared to..': 'Jämfört med..',
  'from last month': 'från förra månaden',
  'settlement types/cashflow': 'avräkningstyper/kassaflöde',
  'On location': 'På plats',
  Invoice: 'Faktura',
  Online: 'Uppkopplad',
  Unpaid: 'Obetald',
  Income: 'Inkomst',
  'Yearly income (estimated)': 'Årsinkomst (uppskattad)',
  'Per month': 'Per månad',
  'Per year': 'Per år',
  'Per day': 'Per day',
  'Per hour': 'Per dag',
  'Active subscribers': 'Aktiva prenumeranter',
  'Subscription revenue': 'Prenumerationsintäkter',
  'Chunk rate': 'Chunk rate',
  Description: 'Beskrivning',

  //Facility access
  'Facility access': 'Tillgång till anläggning',
  'Search in LP,facilities or group name...':
    'Sök i LP, anläggningar eller gruppnamn...',
  'Add new': 'Lägg till ny',
  'Vehicle group': 'Fordonsgrupp',
  Status: 'Status',
  Actions: 'Åtgärder',
  None: 'Ingen',
  'Choose vehicle groups': 'Välj fordonsgrupper',

  'View allowlist': 'Visa godkännandelista',
  'Edit allowlist': 'Redigera godkännandelista',
  'Delete allowlist': 'Ta bort godkännandelista',

  '"Syncing" or "Out of sync" lists may take a few minutes to be synced. To get the latest status refresh:':
    '"Synkronisera" eller "Osynkroniserade" listor kan ta några minuter att synkronisera. Så här hämtar du den senaste statusuppdateringen:',

  facilities: 'anläggningar',
  Add: 'Lägg till',
  Cancel: 'Avbryt',
  'Add allowlist': 'Lägg till godkännandelista',
  'Add vehicles': 'Lägg till fordon',
  'License plates': 'Registreringsskyltar',
  or: 'eller',
  'Import from CSV': 'Importera från CSV',
  'Choose facilities': 'Välj faciliteter',
  'All facilitites': 'Alla faciliteter',
  'View group': 'Visa grupp',
  'Group name': 'Grupp namn',
  Delete: 'Radera',
  'No facilities': 'Inga faciliteter',
  Edit: 'Redigera',
  Resolution: 'Upplösning',
  Hourly: 'Varje timme',
  Daily: 'Dagligen',
  'Subscription revenue trend': 'Trend för prenumerationsintäkter',
  'No results for': 'Inga resultat för',

  //update

  'Search operator...': 'Sökoperatör...',
  'Search...': 'Sök...',
  Name: 'namn',
  'Account Type': 'Kontotyp',
  Email: 'E-post',
  phone: 'telefon',
  Account: 'konto',
  'Mobile phone': 'Mobiltelefon',
  User: 'Användare',
  'User type': 'Användartyp',
  Created: 'Skapad',
  'Last login': 'Senaste inloggning',
  Operator: 'Operatör',
  'Subscription package': 'Prenumerationspaket',
  Vehicles: 'Fordon',
  'Subscription period': 'Prenumerationsperiod',
  'Subscription code': 'Prenumerationskod',
  Price: 'Pris',
  Facility: 'Anläggningen',
  'Car park': 'Parkering',
  'Parking started': 'Parkering startade',
  'Parking ended': 'Parkeringen upphörde',
  'Internal ID': 'Internt ID',
  'Parking attribute': 'Parkeringsattribut',
  ID: 'ID',
  Registration: 'Registrering',
  'Search in name,date,category,location or details...':
    'Sök i namn, datum, kategori, plats eller detaljer...',
  'Date & time': 'Datum Tid',
  Catagory: 'Kategori',
  Location: 'Plats',
  Details: 'Detaljer',
  Warnings: 'Varningar',
  'SwappAccess Admin Dashboard': 'SwappAccess Admin Dashboard',
  'Probability rate': 'Sannolikhetsgrad',
  'Unread license plates': 'Olästa registreringsskyltar',
  'Previous period': 'Tidigare period',
  'Area(Tariff ID)': 'Område (Taxa ID)',
  'Search in name, subscription ID or subscription article code ...':
    'Sök i namn, prenumerations-ID eller prenumerationsartikelkod ...',
  'Search by name,role,email...': 'Sök på namn, roll, e-post...',

  'Email Address': 'E-postadress',
  'The site is currently down for maintenance':
    'Webbplatsen är för närvarande nere för underhåll',
  'We apologize for any inconveniences caused':
    'Vi ber om ursäkt för eventuella besvär',
  Phone: 'Telefon',

  "The page you were looking for doesn't exist.":
    'Sidan du letade efter finns inte.',

  "It's on us, we moved the content to a different page. The search below should help!":
    'Det är upp till oss, vi flyttade innehållet till en annan sida. Sökningen nedan borde hjälpa!',
  'Search terms here...': 'Söktermer här...',
  Search: 'Sök',

  'Go back': 'Gå tillbaka',
  'Go to homepage': 'Gå till hemsidan',
  'There was an error, please try again later':
    'Det uppstod ett fel, försök igen senare',
  'The server encountered an internal error and was not able to complete your request':
    'Servern stötte på ett internt fel och kunde inte slutföra din begäran',

  'Refresh view': 'Uppdatera vyn',
  'Tokyo White React Typescript Admin Dashboard':
    'Tokyo White React Typscript Admin Dashboard',
  'High performance React template built with lots of powerful MUI (Material-UI) components across multiple product niches for fast & perfect apps development processes.':
    'Högpresterande React-mall byggd med massor av kraftfulla MUI-komponenter (Material-UI) över flera produktnischer för snabba och perfekta apputvecklingsprocesser.',
  'There is no data available for this graph':
    'Det finns inga tillgängliga data för denna graf',
  'Coming soon': 'Kommer snart',
  "There's still work to be done on this feature, before you'll be able to see it.":
    'Det återstår fortfarande arbete med den här funktionen innan du kan se den.',
  'Weekly revenue': 'Veckointäkter',
  'Reports for this weeks revenue stream':
    'Rapporter för denna veckas intäktsström',
  EasyPark: 'EasyPark',
  Microlog: 'Mikrolog',
  'View complete report': 'Se hela rapporten',
  Alerts: 'Varningar',
  'Camera status': 'Kamerastatus',
  'Barrier status': 'Barriärstatus',
  'Ticket machine status': 'Biljettautomatstatus',
  On: 'På',
  Off: 'Av',
  'Skip menu filtering': 'Hoppa över menyfiltrering',
  'Last year': 'Förra året',
  'Traffic overview': 'Trafiköversikt',
  'License plate': 'Registreringsskylt',
  Date: 'Datum',
  Started: 'Satte igång',
  Ended: 'Avslutade',
  Duration: 'Varaktighet',
  'Subscriptions active / Max': 'Prenumerationer aktiva / Max',
  Affinity: 'Affinitet',
  'Delete user': 'Ta bort användare',
  Role: 'Roll',
  Save: 'Spara',
  'Per subscription': 'Per abonnemang',
  'Last updated': 'Senast uppdaterad',
  'Something went wrong': 'Något gick fel',
  'Group name is a required field': 'Gruppnamn är ett obligatoriskt fält',
  'Vehicle groups': 'Fordonsgrupper',
  'Edit vehicle group': 'Redigera fordonsgrupp',
  'View vehicle group': 'Visa fordonsgrupp',
  'Add license plates': 'Lägg till registreringsskyltar',
  'Session trend': 'Sessionstrend',
  Transactions: 'Transaktioner',
  Contracts: 'Kontrakt',

  'Forgot password': 'glömt ditt lösenord',
  'Enter your email address below and we will send you a password reset link':
    'Ange din e-postadress nedan så skickar vi en länk för återställning av lösenord till dig',
  Submit: 'Skicka in',

  'Reset password': 'Återställ lösenord',
  'Write and confirm your new password below':
    'Skriv och bekräfta ditt nya lösenord nedan',
  'New password': 'Nytt lösenord',
  'Confirm password': 'Bekräfta lösenord',
  'New password field is required': 'Nytt lösenordsfält krävs',
  'Confirm password field is required': 'Bekräfta lösenordsfältet krävs',
  'Password confirmation must be the same as the password field':
    'Lösenordsbekräftelsen måste vara densamma som lösenordsfältet',
  'We cannot connect to the backend at this point. We apologize for the inconvenience':
    'Vi kan inte ansluta till backend för närvarande. Vi ber om ursäkt för besväret',
  Cameras: 'Kameror',
  'Register camera': 'Registrera kamera',
  'Available cameras': 'Tillgängliga kameror',
  'Installed cameras': 'Installerade kameror',
  'Register lane': 'Registerfält',
  'Add lane': 'Lägg till Lane',
  'Adding lane': 'Lägger till Lane',
  'Select type': 'Välj typ',
  'Serial No.': 'Serienummer.',
  'IP address': 'IP-adress',
  Username: 'Användarnamn',
  Notes: 'Anteckningar',
  'The name field is required': 'Namnfältet krävs',
  'The username field is required': 'Fältet för användarnamn krävs',
  'The Serial No. field is required': 'Fältet i serienummer krävs',
  'The IP address field is required': 'IP -adressfältet krävs',
  'The notes field is required': 'Anteckningsfältet krävs',
  'Please provide valid IP address': 'Ange giltig IP -adress',
  'Assigned to lane': 'Tilldelad körfält',
  'New camera registration': 'Ny kameraregistrering',
  'Add entry lane': 'Lägg till infart',
  'Add exit lane': 'Lägg till avfart',
  Entry: 'Inträde',
  Exit: 'Utgång',
  Lanes: 'Körfält',
  'At least one type must be selected': 'Minst en typ måste väljas',
  Connect: 'Tilldela',
  'Select front camera': 'Välj främre kamera',
  'Select back camera': 'Välj bakre kamera',

  'Upload image': 'Ladda upp bild',

  'Front camera': 'Fram kamera',
  'Back camera': 'Bakre kamera',

  'New entry lane': 'Ny infart',
  'New exit lane': 'Ny utfartsfil',

  'Verification code from email': 'Bekreftelseskode fra e-post',
  'Verification code field is required': 'Verifieringskodsfält krävs',
  'We have sent you an email with a code fore resetting password. Please add the code below':
    'Vi har skickat ett e-postmeddelande till dig med en kod för att återställa lösenord. Vänligen lägg till koden nedan',

  'Delete lane': 'Ta bort körfält',

  "Are you sure you want to delete the lane '{{lane}}'?":
    "Är du säker på att du vill ta bort filen '{{lane}}'?",

  Yes: 'Ja',
  No: 'Nej',

  'Access terminals': 'Access terminaler',
  'Add access terminal': 'Lägg till accessterminal',
  Prompt: 'Prompt',

  Port: 'Hamn',
  Camera: 'Kamera',
  'The Port field is required': 'Fältet Port är obligatoriskt',

  'Access terminal': 'Access terminal',
  'Connected terminal': 'Ansluten terminal',
  'Edit camera': 'Redigera kamera',
  "Are you sure you want to delete the camera '{{camera}}' ?":
    "Är du säker på att du vill ta bort kameran '{{camera}}'?",
  'Delete camera': 'Ta bort kamera',

  'Edit access terminal': 'Redigera åtkomstterminal',
  "Are you sure you want to delete the access terminal '{{accessTerminal}}' ?":
    "Är du säker på att du vill ta bort åtkomstterminalen '{{accessTerminal}}'?",
  'Replace image': 'Byt ut bild',

  'Background color': 'Bakgrundsfärg',
  'Text color': 'Text färg',

  'Hide heading': 'Dölj rubrik',
  'Hide terminal name': 'Dölj terminalnamn',

  Relays: 'Reläer',
  'Register relay': 'Registrera relä',
  'New relay registration': 'Ny stafettregistrering',
  Uninstall: 'Avinstallera',
  'Uninstall access terminal': 'Avinstallera åtkomstterminal',
  'This action will completely delete the access terminal. Would to like to remove it? yes/no':
    'Denna åtgärd kommer att ta bort åtkomstterminalen helt. Vill du ta bort den? Ja Nej',

  'Update connection': 'Uppdatera anslutning',
  'Edit connection': 'Redigera anslutning',
  'Edit IP': 'Redigera IP',
  'Edit relay': 'Redigera relä',
  'Delete relay': 'Ta bort relä',
  Remove: 'Avlägsna',
  'Terminal details': 'Terminaldetaljer',
  'Are you sure you want to delete the Relay':
    'Är du säker på att du vill ta bort reläet',
  'Camera details': 'Kameradetaljer',
  'Relay details': 'Relay Details',

  Relay: 'Relä',
  'Access terminal connection': 'Åtkomstterminalanslutning',
  'Relay port': 'Reläport',
  Connection: 'Förbindelse',

  'Unexpected error in backend. [{{errorStatus}}]':
    'Oväntat fel i backend. [{{errorStatus}}]',

  OK: 'Okej',
  'Camera unreachable': 'Kameran går inte att nå',
  NetworkRelayUnreachable: 'Nätverksrelä går inte att nå',
  'Camera error': 'Kamerafel',
  Unknown: 'Okänd',
  'Network relay error': 'Nätverksreläfel',
  'Network relay unreachable': 'Nätverksreläet går inte att nå',
  Type: 'Typ',

  'Access url': 'Åtkomst till URL',
  'Disconnect camera': 'Lossna kamera',
  'Disconnect relay': 'Koppla bort reläet',
  'Are you sure you want to disconnect the {{deviceName}} - {{ipAddress}} {{deviceType}}?':
    'Är du säker på att du vill koppla bort {{devicename}} - {{ipaddress}} {{devicetype}}?',

  'Select date': 'Välj datum',
  'Ended at': 'Slutade kl',
  'Started at': 'Började kl',
  'Select facility': 'Välj anläggning',

  'Search by license plate…': 'Sök efter registreringsskylt...',
  'Start date range': 'Startdatumintervall',
  'End date range': 'Slutdatumintervall',

  at: 'kl.',

  Direction: 'Riktning',
  Issues: 'frågor',
  Solve: 'Lösa',
  'Registered issues in': 'Registrerade ärenden i',

  Country: 'Land',
  Confirm: 'Bekräfta',
  Drop: 'Släppa',

  'Enable ANPR': 'Aktivera ANPR',
  'Disable ANPR': 'Inaktivera ANPR',
  'Send ANPR events': 'Skicka ANPR-evenemang',
  'Are you sure you want to logout?': 'Är du säker på att du vill logga ut?',
  'Flagged parkings': 'Flaggade parkeringar',
  In: 'I',
  Out: 'Ut',
  Norway: 'Norge',

  'Install kiosks': 'Installera kiosker',
  Install: 'Installera',
  'Edit kiosk': 'Redigera kiosk',

  'Parking session': 'Parkeringssession',
  'Entry time': 'Inträdestid',
  'Exit time': 'Utgångstid',

  'Before trying to resolve issues in flagged parking, try resolving all the issues under':
    'Innan du försöker lösa problem i flaggad parkering, försök att lösa alla problem under',
  'Fixing those is very likely to fix these issues too.':
    'Att fixa dessa kommer mycket troligt att lösa dessa problem också.',
  'Solve recognition issue': 'Lös igenkänningsproblem',
  'Registration number': 'Registreringsnummer',
  Configure: 'Konfigurera',
  'Membership plans': 'Medlemskapsplaner',
  'Edit membership plan': 'Redigera medlemskapsplan',
  'Add membership plan': 'Lägg till medlemskapsplan',
  'Company name': 'Företagsnamn',
  'Period days': 'Perioddagar',
  Memberships: 'Medlemskap',
  'Plan name': 'Planens namn',
  Comments: 'Kommentarer',
  'Are you sure you want to approve': 'Är du säker på att du vill godkänna?',
  'Are you sure you want to reject': 'Är du säker på att du vill avvisa?',
  'Sales period': 'Försäljningsperiod',
  'Max concurrent uses': 'Max samtidiga användningar',
  'Vat rate': 'Skattesats',
  Action: 'Handling',
  private: 'privat',
  'Are you sure you want to make': 'Är du säker på att du vill göra',
  public: 'offentlig',
  'Are you sure you want to': 'Är du säker på att du vill',
  publish: 'publicera',
  'Are you sure you want to cancel subscription for':
    'Är du säker på att du vill avbryta prenumerationen för',
  'Add parking terminal': 'Lägg till parkeringsterminal',
  'Invoicing reference': 'Fakturareferens',
  'Parking terminal': 'Parkeringsterminal',
  'Edit parking terminal': 'Redigera parkeringsautomat',
  'The invoicing reference field is required':
    'Fakturareferensfältet är obligatoriskt',
  'Are you sure you want to install parking terminal? yes/no':
    'Är du säker på att du vill installera parkeringsautomat? ja/nej',
  'Are you sure you want to uninstall parking terminal? yes/no':
    'Är du säker på att du vill avinstallera parkeringsautomat? ja/nej',
  'Add subscription': 'Lägg till prenumeration',
  'Add car park areas': 'Lägg till parkeringsområden',
  'Add to subscription': 'Lägg till prenumeration',
  'Create subscription': 'Skapa prenumeration',
  'Are you sure you want to remove': 'Är du säker på att du vill ta bort',
  subscription: 'prenumeration',
  VAT: 'Moms',
  'Parking areas': 'Parkeringsområden',
  Publish: 'Publicera',
  'Cancel subscription': 'Avbryt prenumeration',
  'Remove subscription': 'Ta bort prenumeration',
  'Make private': 'Gör privat',
  'Make public': 'Gör offentlig',
  'Save subscription': 'Spara prenumeration',
  remove: 'ta bort',
  cancel: 'avbryt',
  'Invoice reference': 'Fakturareferens',
  'Published at': 'Publicerad den',
  Accessibility: 'Tillgänglighet',
};

export default seJSON;
