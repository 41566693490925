import { Card, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { EndUserShape } from 'models/EndUsers';
import { FC, useState, useRef, Key, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { EventLogData } from 'models/EventLog';
import InfiniteScroll from 'react-infinite-scroll-component';
import SuspenseLoader from 'components/_shared/SuspenseLoader';

interface Props {
  user: EndUserShape;
}

const EVENT_LIST: EventLogData[] = [
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '11. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '12. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '13. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '14. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '15. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '16. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '17. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '18. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '19. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '20. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
  {
    datetime: '10. Jun 2022 11:23',
    details: 'Parked in facility',
    location: 'Parked in P-hus Kongsbrun, Level3, A3, Spot 34',
    category: '',
    icon: '',
    user: '',
  },
];

const EndUserEventsTab: FC<Props> = () => {
  const theme = useTheme();
  const [eventList, setEventList] = useState<EventLogData[]>([]);
  const pageNumber = useRef(1);
  const pagesLoaded = useRef<number[]>([]);

  const handleFetchList = () => {
    const pageToLoad = pageNumber.current;
    if (!pagesLoaded.current.includes(pageToLoad)) {
      pageNumber.current += 1;
      const listArray = EVENT_LIST.slice(
        eventList.length,
        pageNumber.current * 10,
      );
      setEventList((prevState) => [...prevState, ...listArray]);
      pagesLoaded.current.push(pageToLoad);
    }
  };

  useEffect(() => {
    const listArray = EVENT_LIST.slice(0, 10);
    setEventList(listArray);
  }, []);

  return (
    <Grid px={4} display="flex" flexWrap={'wrap'}>
      <Grid flex={1}>
        <Card sx={{ margin: 1, py: 3, px: 1 }}>
          <Box paddingLeft={2}>
            <Typography
              color={theme.colors.alpha.black[100]}
              variant="subtitle1"
            >
              Total sessions:
            </Typography>
            <Typography variant="h1" color={theme.colors.alpha.black[70]} p={1}>
              78
            </Typography>
          </Box>
          <Divider />
          <Box marginTop={2} paddingLeft={2}>
            <Typography
              variant="subtitle1"
              color={theme.colors.alpha.black[100]}
            >
              Last sessions:
            </Typography>
            <Typography
              variant="h5"
              color={theme.colors.alpha.black[70]}
              marginTop={1}
            >
              10. Jun 2022 11:23
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid
        id="scrollableDiv"
        sx={{
          height: '400px',
          overflow: 'auto',
        }}
        ml={6}
        flex={4}
      >
        <Timeline sx={{ alignItems: 'flex-start' }} placeholder={undefined}>
          <InfiniteScroll
            dataLength={eventList.length}
            next={handleFetchList}
            hasMore={true}
            loader={
              <div
                style={{
                  overflow: 'hidden',
                }}
              >
                {EVENT_LIST.length !== eventList.length && <SuspenseLoader />}
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            {eventList.map(
              (item: EventLogData, index: Key | null | undefined) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent color="text.secondary">
                      {item.datetime}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ position: 'inherit', top: 2 }}
                        variant="outlined"
                        color="primary"
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ flex: 5 }}>
                      <Typography color="text.primary">
                        {item.location}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                      ></Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              },
            )}
          </InfiniteScroll>
        </Timeline>
      </Grid>
    </Grid>
  );
};

export default EndUserEventsTab;
