import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from 'components/_shared/Search/SearchBox.Icons';
import { useDispatch } from 'store';
import { setIsOpened as setIsSearchOpened } from 'store/slices/Search';

export const HeaderSearchBar = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Grid
      flexDirection="row"
      xs={12}
      md={12}
      lg={12}
      display="flex"
      item
      alignItems="center"
      justifyContent="space-between"
    >
      <SearchIcon
        fontSize="large"
        sx={{ mr: 1, cursor: 'pointer' }}
        color="primary"
        onClick={() => dispatch(setIsSearchOpened(true))}
      />

      <Typography
        sx={{ cursor: 'pointer', flexGrow: 1 }}
        onClick={() => dispatch(setIsSearchOpened(true))}
      >
        {t('Search for license plate, user, transaction etc.')}
      </Typography>
    </Grid>
  );
};
