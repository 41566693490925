import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ParkingTerminalFormData,
  ParkingTerminalShape,
  ParkingTerminalsApiResponse,
} from 'models/Facilities';

import { baseQuery } from '../..';

export type PostType = {
  url: string;
  payload: ParkingTerminalFormData;
};

export const ParkingTerminalApi = createApi({
  reducerPath: 'parkingTerminals',
  tagTypes: ['parking-terminals', 'parking-terminals'],
  baseQuery,
  endpoints: (builder) => ({
    getParkingTerminals: builder.query<ParkingTerminalsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['parking-terminals'],
    }),

    getParkingTerminal: builder.query<ParkingTerminalShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['parking-terminals'],
    }),
    addParkingTerminals: builder.mutation<
      ParkingTerminalsApiResponse,
      PostType
    >({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['parking-terminals'],
    }),
    // assignConnectionToAccessTerminal: builder.mutation<
    //   AccessTerminalsApiResponse,
    //   PostType
    // >({
    //   query: ({ url, payload }) => ({
    //     url: url,
    //     method: 'POST',
    //     body: payload,
    //   }),
    //   invalidatesTags: ['parking-terminals'],
    // }),
  }),
});

export const {
  // useGetAccessTerminalsQuery,
  useGetParkingTerminalsQuery,
  useGetParkingTerminalQuery,
  useAddParkingTerminalsMutation,
  // useAssignConnectionToAccessTerminalMutation,
} = ParkingTerminalApi;
