import QrCode2Icon from '@mui/icons-material/QrCode2';
import {
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';
import BinIcon from 'assests/icons_svg/blue_bin.svg';
import EditIcon from 'assests/icons_svg/blue_edit.svg';
import PlusIcon from 'assests/icons_svg/blue_plus.svg';
import VehicleIcon from 'assests/icons_svg/car.svg';
import EmptyTable from 'atoms/components/EmptyTable';
import StyledTableCell from 'atoms/components/StyledTableCell';
import CustomDialog from 'components/_shared/Dialog/dialog';
import { VehicleShape } from 'models/EndUsers';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteDevice from '../VehicleActions/deleteDevice';
import EditVehicle from '../VehicleActions/editVehicle';
import QrCode from '../VehicleActions/qrcode';
import InfiniteScroll from 'react-infinite-scroll-component';
import SuspenseLoader from 'components/_shared/SuspenseLoader';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? '#1A2027' : theme.colors.alpha.white[100],
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

interface Props {
  list: VehicleShape[];
  handleFetchList: () => void;
}

const EndUserVehiclesList: FC<Props> = ({
  list,
  handleFetchList,
}): JSX.Element => {
  const { t } = useTranslation();

  const [vehiclesTabList, setVehiclesList] = useState<VehicleShape[]>([]);

  const [qrCodeEntity, setQREntity] = useState<undefined | VehicleShape>();
  const [openEditUserEntity, setVehicleEditEntity] = useState<
    undefined | VehicleShape
  >();
  const [openDeleteUserEntity, setVehicleDeleteEntity] = useState<
    undefined | VehicleShape
  >();

  useEffect(() => {
    setVehiclesList(list);
  }, [list]);

  const theme = useTheme();

  return (
    <>
      {vehiclesTabList.length > 0 && (
        <>
          <TableContainer>
            <InfiniteScroll
              dataLength={vehiclesTabList.length}
              next={handleFetchList}
              hasMore={true}
              loader={
                <div
                  style={{
                    position: 'absolute',
                    marginLeft: '50%',
                    height: '60px',
                  }}
                >
                  {list.length !== vehiclesTabList.length && <SuspenseLoader />}
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ border: 'none' }}>
                    <TableCell></TableCell>
                    <TableCell>{t('ID')}</TableCell>
                    <TableCell>{t('Registration')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehiclesTabList?.map((vehicle, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>
                          <img src={VehicleIcon} alt="vehicle_icon" />
                        </TableCell>

                        <StyledTableCell title={vehicle.VehicleGuid} />

                        <StyledTableCell title={vehicle.LicensePlate} />

                        <TableCell>
                          <Stack
                            direction="row"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={2}
                          >
                            {vehicle.IdCarrierTypes?.map(
                              (item, index) =>
                                index < 3 && <Item key={index}>{item}</Item>,
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Edit User" arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter,
                                },
                                color: theme.palette.primary.main,
                              }}
                              color="inherit"
                              size="medium"
                              onClick={() => setVehicleEditEntity(vehicle)}
                            >
                              <img src={EditIcon} alt="edit_icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="QR Code" arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter,
                                },
                                color: theme.palette.primary.main,
                              }}
                              color="inherit"
                              size="medium"
                              onClick={() => setQREntity(vehicle)}
                            >
                              <QrCode2Icon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Add User" arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter,
                                },
                                color: theme.palette.primary.main,
                              }}
                              color="inherit"
                              size="medium"
                            >
                              <img src={PlusIcon} alt="plus_icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete User" arrow>
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.error.lighter,
                                },
                                color: theme.palette.error.main,
                              }}
                              color="inherit"
                              size="medium"
                              onClick={() => setVehicleDeleteEntity(vehicle)}
                            >
                              <img src={BinIcon} alt="bin_icon" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
          <Divider />
        </>
      )}
      {vehiclesTabList.length === 0 && <EmptyTable />}
      {qrCodeEntity && (
        <CustomDialog onClose={() => setQREntity(undefined)} title="QR-Code">
          <QrCode vehicleData={qrCodeEntity} />
        </CustomDialog>
      )}
      {openEditUserEntity && (
        <CustomDialog
          onClose={() => setVehicleEditEntity(undefined)}
          title="Edit vehicle details"
        >
          <EditVehicle vehicleData={openEditUserEntity} />
        </CustomDialog>
      )}
      {openDeleteUserEntity && (
        <CustomDialog
          onClose={() => setVehicleDeleteEntity(undefined)}
          title="Delete Vehicle"
        >
          <DeleteDevice vehicleData={openDeleteUserEntity} />
        </CustomDialog>
      )}
    </>
  );
};

export default EndUserVehiclesList;
