import { Typography } from '@mui/material';

const EmptyTable = (): JSX.Element => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        p: 4,
        textAlign: 'center',
      }}
      gutterBottom
      component="div"
    >
      No records found to display!
    </Typography>
  );
};

export default EmptyTable;
