import { FC, forwardRef, ReactElement, ReactNode, useMemo } from 'react';

import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Breakpoint,
} from '@mui/material';
import Slide from '@mui/material/Slide';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { TransitionProps } from '@mui/material/transitions';
import { TranslatableString, translatedStrings } from 'i18n/translations/no';
import { useTranslation } from 'react-i18next';

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    backgroundColor: theme.palette.background.default,
    border: 'none',
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogProps {
  onClose: () => void;
  children: React.ReactNode;
}

const BootstrapDialogTitle: FC<DialogProps> = (props) => {
  const { children, onClose, ...other } = props;
  const theme = useTheme();

  return (
    <DialogTitle
      sx={{
        m: 0,
        backgroundColor: theme.palette.background.default,
      }}
      {...other}
    >
      {children}

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

type Props = {
  title?: string | TranslatableString;
  onClose: () => void;
  width?: Breakpoint;
  text?: string | ReactNode;
  children: ReactNode | ReactNode[];
};

const Modal: FC<Props> = ({ title, onClose, width = 'md', children }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const parsedTitle = useMemo(() => {
    if (typeof title === 'string' && translatedStrings.includes(title)) {
      return t(title as TranslatableString);
    }
    return title;
  }, [t, title]);

  return (
    <DialogWrapper
      onClose={onClose}
      maxWidth={width}
      fullWidth={true}
      open={true}
      sx={{ p: 0, background: theme.colors.blackAlt.main }}
      TransitionComponent={Transition}
    >
      <BootstrapDialogTitle onClose={onClose}>
        {parsedTitle}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </DialogWrapper>
  );
};

export default Modal;
