import { EndUserShape } from 'models/EndUsers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  useTheme,
} from '@mui/material';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';

type Props = {
  user: EndUserShape;
  handleDelete: (user: EndUserShape) => void;
};

const EndUserInfoTab: FC<Props> = ({ user, handleDelete }) => {
  const { t } = useTranslation();
  const hasDeleteLink = getApiHrefByRel(user.Links, 'delete');
  const theme = useTheme();
  const error = {
    '&:hover': {
      background: theme.colors.error.lighter,
    },
    background: theme.colors.error.lighter,
    color: theme.palette.error.main,
  };
  return (
    <Grid
      sx={{
        px: 4,
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item md={5} xs={12}>
        <Card>
          <Box py={2} px={3}>
            <Typography variant="h4" gutterBottom>
              {t('Account')}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              height: 300,
            }}
          >
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                {t('Account Type')}
              </Typography>
              <Typography variant="subtitle2">{user.AccountType}</Typography>
            </Box>
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                {t('Name')}
              </Typography>
              <Typography variant="subtitle2">{user.Name}</Typography>
            </Box>
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1, mr: 2 }} variant="h5" gutterBottom>
                {t('Email')}
              </Typography>
              <Typography variant="subtitle2">{user.Email}</Typography>
            </Box>
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                {t('Mobile phone')}
              </Typography>
              <Typography variant="subtitle2">{user.Phone}</Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item md={5} xs={12}>
        <Card>
          <Box py={2} px={3} sx={{ display: 'flex' }}>
            <Typography variant="h4" gutterBottom sx={{ flexGrow: 1, mt: 1 }}>
              {t('User')}
            </Typography>
            {hasDeleteLink && (
              <Button onClick={() => handleDelete(user)} sx={error}>
                Delete
              </Button>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              height: 300,
            }}
          >
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                {t('User type')}
              </Typography>
              <Typography variant="subtitle2">{user.AppRole}</Typography>
            </Box>
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                {t('Status')}
              </Typography>
              <Typography variant="subtitle2">{user.AccountStatus}</Typography>
            </Box>
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                {t('Created')}
              </Typography>
              <Typography variant="subtitle2">{user.Created}</Typography>
            </Box>
            <Box py={2} px={3} display="flex">
              <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                {t('Last login')}
              </Typography>
              <Typography variant="subtitle2">{user.LastLogin}</Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EndUserInfoTab;
