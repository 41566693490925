import { SearchBoxItemGroup } from '../../SearchBox.ItemGroup';

import { FilledStarIcon } from '../../SearchBox.Icons';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { SearchHistoryItemShape } from 'models/Search';
import { useEffect, useState, FC } from 'react';

import SearchHistoryItem from '../Item';
import { useBaseLinks } from 'store/Auth';
import { useGetSavedSearchesQuery } from 'store/_Api/Search';
import useSearchActions from 'store/Search/useSearchActions';
import { skipToken } from '@reduxjs/toolkit/dist/query';

type Props = {
  handleSelectItem: (item: SearchHistoryItemShape) => void;
  limit?: number;
};

const SavedSearches: FC<Props> = ({ handleSelectItem, limit = 4 }) => {
  const { unSave, remove } = useSearchActions();
  const { getLink } = useBaseLinks();
  const savedSearchesUrl = getLink('saved-searches');
  const [savedSearchLink, setSavedSearchLink] = useState(savedSearchesUrl);
  useEffect(() => {
    setSavedSearchLink(savedSearchesUrl);
  }, [savedSearchesUrl]);

  const { data } = useGetSavedSearchesQuery(savedSearchLink ?? skipToken);

  const items = data?.Items as SearchHistoryItemShape[] | undefined;
  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <>
      <SearchBoxItemGroup title="Saved searches">
        {items.slice(0, limit).map((item, index: number) => (
          <SearchHistoryItem
            key={index}
            data={item}
            handleSelect={() => handleSelectItem(item)}
            icon={
              <IconButton size="small" onClick={() => unSave(item)}>
                <FilledStarIcon />
              </IconButton>
            }
            actions={
              <Box>
                <IconButton size="small" onClick={() => remove(item)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            }
          />
        ))}
      </SearchBoxItemGroup>
    </>
  );
};

export default SavedSearches;
