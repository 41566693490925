import { LinkShape } from 'models/Link';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinksMap } from './useBaseLinks';
import { makeMapForLinks } from '_utils/links';

interface LinksWithMapState {
  links: LinkShape[];
  map: LinksMap | undefined;
}
interface BaseLinksState extends LinksWithMapState {
  operatorLinks: LinksWithMapState | undefined;
}

const initialState: BaseLinksState = {
  links: [],
  map: undefined,
  operatorLinks: undefined,
};

const BaseLinksSlice = createSlice({
  name: 'base_links',
  initialState,
  reducers: {
    setLinks(state: BaseLinksState, action: PayloadAction<LinkShape[]>) {
      const links = action.payload;

      state.links = links;
      state.map = makeMapForLinks(links);
    },
    removeLinkByRel(state: BaseLinksState, action: PayloadAction<string>) {
      const rel = action.payload;
      const links = state.links.filter((link) => link.Rel !== rel);

      state.map = makeMapForLinks(links);
      state.links = links;
    },
    setLink(state: BaseLinksState, action: PayloadAction<LinkShape>) {
      const thisLink = action.payload;

      const links = state.links.filter((link) => link.Rel !== thisLink.Rel);
      links.push(thisLink);

      state.map = makeMapForLinks(links);
      state.links = links;
    },
    setOperatorLinks(
      state: BaseLinksState,
      action: PayloadAction<LinkShape[] | undefined>,
    ) {
      const links = action.payload;

      if (links !== undefined) {
        state.operatorLinks = {
          links,
          map: makeMapForLinks(links),
        };
      } else {
        state.operatorLinks = undefined;
      }
    },
  },
});

export const { setLinks, removeLinkByRel, setLink, setOperatorLinks } =
  BaseLinksSlice.actions;

export const BaseLinksReducer = BaseLinksSlice.reducer;

export default BaseLinksSlice;
