import { FC } from 'react';
import { useTheme } from '@mui/material';
interface Props {
  fill?: string;
}
const CalendarIcon: FC<Props> = ({ fill }): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="42"
      height="44"
      viewBox="0 0 42 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8125 30.464C11.8125 31.5714 12.6738 32.4327 13.7812 32.4327H28.2188C29.2852 32.4327 30.1875 31.5714 30.1875 30.464V19.3077H11.8125V30.464ZM24.9375 22.4249C24.9375 22.1788 25.1426 21.9327 25.4297 21.9327H27.0703C27.3164 21.9327 27.5625 22.1788 27.5625 22.4249V24.0656C27.5625 24.3527 27.3164 24.5577 27.0703 24.5577H25.4297C25.1426 24.5577 24.9375 24.3527 24.9375 24.0656V22.4249ZM24.9375 27.6749C24.9375 27.4288 25.1426 27.1827 25.4297 27.1827H27.0703C27.3164 27.1827 27.5625 27.4288 27.5625 27.6749V29.3156C27.5625 29.6027 27.3164 29.8077 27.0703 29.8077H25.4297C25.1426 29.8077 24.9375 29.6027 24.9375 29.3156V27.6749ZM19.6875 22.4249C19.6875 22.1788 19.8926 21.9327 20.1797 21.9327H21.8203C22.0664 21.9327 22.3125 22.1788 22.3125 22.4249V24.0656C22.3125 24.3527 22.0664 24.5577 21.8203 24.5577H20.1797C19.8926 24.5577 19.6875 24.3527 19.6875 24.0656V22.4249ZM19.6875 27.6749C19.6875 27.4288 19.8926 27.1827 20.1797 27.1827H21.8203C22.0664 27.1827 22.3125 27.4288 22.3125 27.6749V29.3156C22.3125 29.6027 22.0664 29.8077 21.8203 29.8077H20.1797C19.8926 29.8077 19.6875 29.6027 19.6875 29.3156V27.6749ZM14.4375 22.4249C14.4375 22.1788 14.6426 21.9327 14.9297 21.9327H16.5703C16.8164 21.9327 17.0625 22.1788 17.0625 22.4249V24.0656C17.0625 24.3527 16.8164 24.5577 16.5703 24.5577H14.9297C14.6426 24.5577 14.4375 24.3527 14.4375 24.0656V22.4249ZM14.4375 27.6749C14.4375 27.4288 14.6426 27.1827 14.9297 27.1827H16.5703C16.8164 27.1827 17.0625 27.4288 17.0625 27.6749V29.3156C17.0625 29.6027 16.8164 29.8077 16.5703 29.8077H14.9297C14.6426 29.8077 14.4375 29.6027 14.4375 29.3156V27.6749ZM28.2188 14.0577H26.25V12.089C26.25 11.7609 25.9219 11.4327 25.5938 11.4327H24.2812C23.9121 11.4327 23.625 11.7609 23.625 12.089V14.0577H18.375V12.089C18.375 11.7609 18.0469 11.4327 17.7188 11.4327H16.4062C16.0371 11.4327 15.75 11.7609 15.75 12.089V14.0577H13.7812C12.6738 14.0577 11.8125 14.9601 11.8125 16.0265V17.9952H30.1875V16.0265C30.1875 14.9601 29.2852 14.0577 28.2188 14.0577Z"
        fill={fill || theme.colors.info.main}
      />
    </svg>
  );
};
export default CalendarIcon;
