import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndPointsResponseData } from 'Api/Endpoints';
import { UserApiLoginPostData } from 'Api/User/UserApi';
import { baseQuery } from '..';
import { LoginResponseShape, UserAccountShape } from 'models/User';

export type UserApiLoginQueryData = {
  url: string;
  userData: UserApiLoginPostData;
};

export const AuthApi = createApi({
  reducerPath: 'authApi',
  tagTypes: ['BaseLinks', 'Profile'],
  baseQuery,
  endpoints: (builder) => ({
    /**
     * Endpoint for getting all baseLinks
     */
    baseApiLinks: builder.query<ApiEndPointsResponseData, void>({
      query: () => ({ url: '/byr-ui' }),
      providesTags: ['BaseLinks'],
    }),
    /**
     * Endpoint for getting user profile
     * (url for profile must be provided when making the query)
     */
    getProfile: builder.query<UserAccountShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['Profile'],
    }),
    /**
     * Endpoint for logging in
     * (url for profile must be provided when making the query)
     */
    login: builder.mutation<LoginResponseShape, UserApiLoginQueryData>({
      query: ({ url, userData }) => ({
        url: url,
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['BaseLinks', 'Profile'],
    }),
    /**
     * Endpoint for logging out
     * (url for profile must be provided when making the query)
     */
    logout: builder.mutation<void, string>({
      query: (url) => ({
        url: url,
        method: 'POST',
      }),
      invalidatesTags: ['BaseLinks'],
    }),
  }),
});

export const {
  useBaseApiLinksQuery,
  useGetProfileQuery,
  useLoginMutation,
  useLogoutMutation,
} = AuthApi;
