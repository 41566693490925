import { RouteObject } from 'react-router-dom';
import Loader from 'router/Loader';
import { lazy } from 'react';
import FacilitiesRouter from './FacilitiesRouter';

const CameraFeed = Loader(
  lazy(() => import('content/pages/Private/CameraFeed')),
);
const ParkingSessions = Loader(
  lazy(() => import('content/pages/Private/ParkingSessions')),
);
const ParkingsRequiringAttention = Loader(
  lazy(() => import('content/pages/Private/ParkingsRequiringAttention')),
);

const Pricing = Loader(lazy(() => import('content/pages/Private/Pricing')));
const EventLog = Loader(lazy(() => import('content/pages/Private/EventLog')));
const Subscriptions = Loader(
  lazy(() => import('content/pages/Private/Subscriptions')),
);
const TeamPage = Loader(lazy(() => import('content/pages/Private/Team')));
const EndUsersPage = Loader(
  lazy(() => import('content/pages/Private/EndUsers')),
);
const MatchingPage = Loader(
  lazy(() => import('content/pages/Private/Matching')),
);
const OldMatchingList = Loader(
  lazy(() => import('content/pages/Private/OldMatching')),
);
const FacilityListPage = Loader(
  lazy(() => import('content/pages/Private/FacilityAccess')),
);

const VehicleGroupsPage = Loader(
  lazy(() => import('content/pages/Private/VehicleGroups')),
);
const MembershipsPage = Loader(
  lazy(() => import('content/pages/Private/Memberships')),
);

const managementRouter: RouteObject[] = [
  {
    path: '/camera-feed',
    element: <CameraFeed />,
  },
  ...FacilitiesRouter,
  {
    path: '/subscription-plans',
    element: <Subscriptions />,
  },
  {
    path: '/parkings',
    element: <ParkingSessions />,
  },
  {
    path: '/parkings-requiring-attention',
    element: <ParkingsRequiringAttention />,
  },
  {
    path: '/pricing',
    element: <Pricing />,
  },
  {
    path: '/old-matching',
    element: <OldMatchingList />,
  },
  {
    path: '/matching',
    element: <MatchingPage />,
  },
  {
    path: '/event-log',
    element: <EventLog />,
  },

  {
    path: '/end-users',
    element: <EndUsersPage />,
  },
  {
    path: '/customers',
    element: <EndUsersPage />,
  },
  {
    path: '/team',
    element: <TeamPage />,
  },
  {
    path: '/facility-access',
    element: <FacilityListPage />,
  },
  {
    path: '/vehicle-groups',
    element: <VehicleGroupsPage />,
  },
  {
    path: '/memberships',
    element: <MembershipsPage />,
  },
];

export default managementRouter;
