import { alpha, Box, lighten, styled, useTheme, Hidden } from '@mui/material';
import useBaseLinks from 'store/Auth/Links/useBaseLinks';
import OperatorSelector from 'components/Analytics/Header/Selector/Operator';
import LanguageSwitcher from 'components/_shared/LanguageSwitcher';
import { HeaderSearchBar } from './HeaderSearchBar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useContext } from 'react';
import { MenuIcon } from 'components/_shared/Search/SearchBox.Icons';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background ?? '', 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`,
);

const Header = (): JSX.Element => {
  const theme = useTheme();
  const { toggleSidebar } = useContext(SidebarContext);
  const { getLink } = useBaseLinks();
  const headerSearch = getLink('search');
  const operators = getLink('operators');

  return (
    <HeaderWrapper
      display="flex"
      justifyContent="space-between"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
              alpha(lighten(theme.colors.primary.main, 0.7), 0.15) +
              ', 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)'
            : '0px 2px 8px -3px ' +
              alpha(theme.colors.alpha.black[100], 0.2) +
              ', 0px 5px 22px -4px ' +
              alpha(theme.colors.alpha.black[100], 0.1),
      }}
    >
      {headerSearch && <HeaderSearchBar />}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Hidden lgUp={true}>
          <MenuIcon fontSize="large" color="primary" onClick={toggleSidebar} />
        </Hidden>
        {operators && <OperatorSelector />}
        <LanguageSwitcher />
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
