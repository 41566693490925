import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import CustomDialog from 'components/_shared/Dialog/dialog';
import { VehicleShape } from 'models/EndUsers';

interface Props {
  vehicle: VehicleShape;
  handleClose: () => void;
}

const CustomerVehicleModal = ({ vehicle, handleClose }: Props): JSX.Element => {
  return (
    <CustomDialog
      header={
        <Typography variant="h3" color="text.secondary">
          Customer Vehicle - {vehicle.LicensePlate}
        </Typography>
      }
      onClose={handleClose}
      title={vehicle.LicensePlate}
    >
      <Grid
        sx={{
          px: 4,
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item md={6} xs={12}>
          <Card>
            <Box py={2} px={3} textAlign="center">
              <Typography variant="h4" gutterBottom>
                Vehicle Detail
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  VehicleGuid
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {vehicle.VehicleGuid}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  LicensePlate
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {vehicle.LicensePlate}
                </Typography>
              </Box>

              <Box py={2} px={3} display="flex">
                <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
                  IdCarrierTypes
                </Typography>
                <Typography pl={2} variant="subtitle2">
                  {vehicle.IdCarrierTypes}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default CustomerVehicleModal;
