import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { styled } from '@mui/system';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  cursor: pointer;
  color: #808080;
  font-size: 0.875rem;
  font-weight: bold;
  width: 100%;
  padding: 14px 16px;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
  border: none;
  background: none;
  border-bottom: 2px solid #87ceeb;

  &:focus {
    color: #fff;
  }

  &.${tabUnstyledClasses.selected} {
    border: 2px solid #87ceeb;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    color: #808080;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  /* border-color: '#92a8d1'; */
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export { Tab, TabsList, TabPanel };
