import { ReactComponent as Dashboard } from './Dashboard.svg';
import { ReactComponent as Analytics } from './Analytics.svg';
import { ReactComponent as Financial } from './Financial.svg';
import { ReactComponent as Monitoring } from './Monitoring.svg';
import { ReactComponent as CameraFeed } from './Camera-feed.svg';
import { ReactComponent as FacilitiesP } from './Facilities-P.svg';
import { ReactComponent as Pricing } from './Pricing.svg';
import { ReactComponent as Users } from './Users.svg';
import { ReactComponent as Matching } from './Matching.svg';
import { ReactComponent as ParkingSessions } from './Parking-sessions.svg';
import { ReactComponent as FAQ } from './FAQ.svg';
import { ReactComponent as Support } from './Support.svg';
import { ReactComponent as Documentation } from './Documentation.svg';
import { ReactComponent as LogOut } from './Log-out.svg';
import { ReactComponent as SearchInMenu } from './Search-In-Menu.svg';
import { ReactComponent as Growth } from './Growth.svg';
import { ReactComponent as Warning } from './Warning.svg';
import { ReactComponent as EventLog } from './EventLog.svg';
import { ReactComponent as FacilitiesP2 } from './Facilities-P2.svg';
import { ReactComponent as Network } from 'assests/icons_svg/network.svg';
import { ReactComponent as Plus } from 'assests/icons_svg/plus.svg';
import { ReactComponent as Calender } from './Calender.svg';
import { ReactComponent as Clock } from 'assests/icons_svg/clock.svg';
import { ReactComponent as Teampage } from './Team.svg';
import { ReactComponent as TeamIcon } from './TeamIcon.svg';
import { ReactComponent as AllowListIcon } from './AllowlistIcon.svg';
import { ReactComponent as AllowListHeaderIcon } from './AllowListHeader.svg';
import { ReactComponent as Subscription } from './Subscription.svg';
import { ReactComponent as Information } from './Information.svg';
import { ReactComponent as View } from './EyeIcon.svg';
import { ReactComponent as Edit } from 'assests/icons_svg/edit.svg';
import { ReactComponent as Car } from 'assests/icons_svg/vehicle.svg';
import { ReactComponent as Camera } from './Camera.svg';
import { ReactComponent as Gate } from './Gate.svg';
import { ReactComponent as Sensor } from './Sensor.svg';
import { ReactComponent as GoingUp } from './GoingUp.svg';
import { ReactComponent as GoingDown } from './GoingDown.svg';
import { ReactComponent as EventCalendar } from './EventCalendar.svg';
import { CSSProperties } from 'react';

const svgStyle = {
  height: '1em',
  width: '1em',
  opacity: '0.4',
};

const getIconStyle = (style: CSSProperties): CSSProperties => {
  return Object.getOwnPropertyNames(style).length === 0 ? svgStyle : style;
};

export const DashboardIcon = (style: CSSProperties): JSX.Element => {
  return <Dashboard style={getIconStyle(style)} />;
};

export const AnalyticsIcon = (style: CSSProperties): JSX.Element => {
  return <Analytics style={getIconStyle(style)} />;
};

export const FinancialIcon = (style: CSSProperties): JSX.Element => {
  return <Financial style={getIconStyle(style)} />;
};

export const MonitoringIcon = (style: CSSProperties): JSX.Element => {
  return <Monitoring style={getIconStyle(style)} />;
};

export const CameraFeedIcon = (style: CSSProperties): JSX.Element => {
  return <CameraFeed style={getIconStyle(style)} />;
};

export const PricingIcon = (style: CSSProperties): JSX.Element => {
  return <Pricing style={getIconStyle(style)} />;
};

export const FacilitiesPIcon = (style: CSSProperties): JSX.Element => {
  return <FacilitiesP style={getIconStyle(style)} />;
};

export const UsersIcon = (style: CSSProperties): JSX.Element => {
  return <Users style={getIconStyle(style)} />;
};

export const MatchingIcon = (style: CSSProperties): JSX.Element => {
  return <Matching style={getIconStyle(style)} />;
};

export const ParkingSessionsIcon = (style: CSSProperties): JSX.Element => {
  return <ParkingSessions style={getIconStyle(style)} />;
};

export const FAQIcon = (style: CSSProperties): JSX.Element => {
  return <FAQ style={getIconStyle(style)} />;
};

export const SupportIcon = (style: CSSProperties): JSX.Element => {
  return <Support style={getIconStyle(style)} />;
};

export const DocumentationIcon = (style: CSSProperties): JSX.Element => {
  return <Documentation style={getIconStyle(style)} />;
};

export const LogOutIcon = (style: CSSProperties): JSX.Element => {
  return <LogOut style={getIconStyle(style)} />;
};

export const SearchInMenuIcon = (style: CSSProperties): JSX.Element => {
  return <SearchInMenu style={getIconStyle(style)} />;
};

export const GrowthIcon = (style: CSSProperties): JSX.Element => {
  return <Growth style={getIconStyle(style)} />;
};

export const WarningIcon = (style: CSSProperties): JSX.Element => {
  return <Warning style={getIconStyle(style)} />;
};

export const FacilitiesP2Icon = (style: CSSProperties): JSX.Element => {
  return <FacilitiesP2 style={getIconStyle(style)} />;
};

export const NetworkIcon = (style: CSSProperties): JSX.Element => {
  return <Network style={getIconStyle(style)} />;
};

export const PlusIcon = (style: CSSProperties): JSX.Element => {
  return <Plus style={getIconStyle(style)} />;
};

export const CalenderIcon = (style: CSSProperties): JSX.Element => {
  return <Calender style={getIconStyle(style)} />;
};

export const ClockIcon = (style: CSSProperties): JSX.Element => {
  return <Clock style={getIconStyle(style)} />;
};

export const EventLogIcon = (style: CSSProperties): JSX.Element => {
  return <EventLog style={getIconStyle(style)} />;
};

export const TeampageIcon = (style: CSSProperties): JSX.Element => {
  return <Teampage style={getIconStyle(style)} />;
};

export const TeamPageIcon = (style: CSSProperties): JSX.Element => {
  return <TeamIcon style={getIconStyle(style)} />;
};

export const AllowListPageIcon = (style: CSSProperties): JSX.Element => {
  return <AllowListIcon style={getIconStyle(style)} />;
};

export const AllowListPageHeaderIcon = (style: CSSProperties): JSX.Element => {
  return <AllowListHeaderIcon style={getIconStyle(style)} />;
};

export const SubscriptionIcon = (style: CSSProperties): JSX.Element => {
  return <Subscription style={getIconStyle(style)} />;
};

export const InformationIcon = (style: CSSProperties): JSX.Element => {
  return <Information style={getIconStyle(style)} />;
};

export const ViewIcon = (style: CSSProperties): JSX.Element => {
  return <View style={getIconStyle(style)} />;
};

export const EditIcon = (style: CSSProperties): JSX.Element => {
  return <Edit style={style} />;
};

export const CarIcon = (style: CSSProperties): JSX.Element => {
  return <Car style={getIconStyle(style)} />;
};

export const CameraIcon = (): JSX.Element => {
  return <Camera />;
};

export const GateIcon = (): JSX.Element => {
  return <Gate />;
};

export const SensorIcon = (): JSX.Element => {
  return <Sensor />;
};

export const GoingUpIcon = (): JSX.Element => {
  return <GoingUp />;
};

export const GoingDownIcon = (): JSX.Element => {
  return <GoingDown />;
};

export const EventCalendarIcon = (): JSX.Element => {
  return <EventCalendar />;
};
