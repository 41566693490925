import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SearchState = {
  isOpened: boolean;
};

const initialState: SearchState = {
  isOpened: false,
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsOpened(state: SearchState, action: PayloadAction<boolean>) {
      state.isOpened = action.payload;
    },
  },
});

export const { setIsOpened } = slice.actions;

export const SearchReducer = slice.reducer;
