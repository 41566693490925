import { VehicleGroupsApiResponse } from '../../../models/VehicleGroup/VehicleGroupModel';
import { createApi } from '@reduxjs/toolkit/query/react';
import { TwoLineGraphDataShape } from 'models/Analytics/Sessions';
import {
  AnalyticsApiResponse,
  FacilityAnalyticsApiResponse,
  ParkingDurationApiResponse,
} from 'models/Analytics';
import { OperatorsApiResponse } from 'models/Analytics/Operator';
import {
  RevenueApiResponse,
  SubscriptionRevenueApiResponse,
} from 'models/Analytics/Revenue/index';
import { SessionsSplitByCountApiResponse } from 'models/Analytics/Sessions';

import { baseQuery } from '..';

export const AnalyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery,
  endpoints: (builder) => ({
    getAnalytics: builder.query<AnalyticsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getAnalyticsForFacility: builder.query<
      FacilityAnalyticsApiResponse,
      string
    >({
      query: (url) => ({
        url: url,
      }),
    }),
    getRevenue: builder.query<RevenueApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
    }),

    getOperators: builder.query<OperatorsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getSessionsSplitCount: builder.query<
      SessionsSplitByCountApiResponse,
      string
    >({
      query: (url) => ({
        url: url,
      }),
    }),
    getSubscriptionRevenue: builder.query<
      SubscriptionRevenueApiResponse,
      string
    >({
      query: (url) => ({
        url: url,
      }),
    }),
    getParkingDuration: builder.query<ParkingDurationApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getTowLineGraphData: builder.query<TwoLineGraphDataShape, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getVehicleGroupsData: builder.query<VehicleGroupsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
    }),
  }),
});

export const {
  useGetAnalyticsQuery,
  useGetAnalyticsForFacilityQuery,
  useGetRevenueQuery,
  useGetOperatorsQuery,
  useGetSessionsSplitCountQuery,
  useGetSubscriptionRevenueQuery,
  useGetParkingDurationQuery,
  useGetTowLineGraphDataQuery,
  useGetVehicleGroupsDataQuery,
} = AnalyticsApi;
