import { Box, Card, Container, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;    
`,
);

const MainContent = styled(Box)(
  () => `
  width: 100%;
  display: flex;
  align-items: center;
`,
);

type Props = {
  title: string;
  subTitle: string;
  children?: ReactNode;
};

const FormLayout = ({ title, subTitle, children }: Props): JSX.Element => {
  return (
    <Content>
      <MainContent>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          maxWidth="sm"
        >
          <Card
            sx={{
              p: 4,
              my: 4,
              width: '100%',
            }}
          >
            <Box textAlign="center">
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3,
                }}
              >
                {subTitle}
              </Typography>
            </Box>

            {children}
          </Card>
        </Container>
      </MainContent>
    </Content>
  );
};

export default FormLayout;
