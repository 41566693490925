import { createApi } from '@reduxjs/toolkit/query/react';
import { KiosksApiResponse, KioskShape } from 'models/Facilities';

import { baseQuery } from '../..';

export const KiosksApi = createApi({
  reducerPath: 'kiosks',
  tagTypes: ['kiosks', 'kiosk'],
  baseQuery,
  endpoints: (builder) => ({
    getKiosks: builder.query<KiosksApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['kiosks'],
    }),
    getKiosk: builder.query<KioskShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['kiosk'],
    }),
  }),
});

export const { useGetKiosksQuery, useGetKioskQuery } = KiosksApi;
