import defaultAxios from 'axios';

const Api = defaultAxios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response?.data;
    if (!response) {
      return Promise.reject('Unexpected error');
    }

    if (response.Errors) {
      return Promise.reject(response);
    }

    return Promise.reject(response.Message ?? response);
  },
);

export default Api;
