import { useState, useEffect, FC } from 'react';
import { Card } from '@mui/material';

import { EndUserShape, VehicleShape } from 'models/EndUsers';

import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import EndUserVehiclesList from './List/EndUserVehiclesList';
import { useVehiclesListQuery } from 'store/_Api/Users';

interface Props {
  user: EndUserShape;
}

const EndUserVehiclesTab: FC<Props> = ({ user }): JSX.Element => {
  const [list, setList] = useState<VehicleShape[]>([]);
  const [hasNextLink, setHasNextLink] = useState(true);
  const vehicleUrl = getApiHrefByRel(user?.Links, 'vehicles');
  const [link, setLink] = useState('');

  useEffect(() => {
    if (vehicleUrl) {
      setLink(vehicleUrl);
    }
  }, [vehicleUrl]);

  const { data } = useVehiclesListQuery(link, { skip: !link });
  const vehiclesListData = data?.Items;
  const listActionLinks = data?.Links;

  const onScroll = () => {
    if (listActionLinks) {
      const nextLink = getApiHrefByRel(listActionLinks, 'next');
      if (nextLink && hasNextLink) {
        setLink(nextLink);
      } else {
        setHasNextLink(false);
      }
    }
  };

  useEffect(() => {
    if (vehiclesListData) {
      setList((prevState) => [...prevState, ...vehiclesListData]);
      return;
    }
  }, [vehiclesListData, data, link]);

  return (
    <Card sx={{ width: '100%' }}>
      <EndUserVehiclesList list={list} handleFetchList={onScroll} />
    </Card>
  );
};

export default EndUserVehiclesTab;
