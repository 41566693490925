import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleGroupModel } from 'models/VehicleGroup';

export type VehicleGroupMapShape = {
  [key: string]: VehicleGroupModel;
};

type VehicleGroupsState = {
  map: VehicleGroupMapShape;
};

const initialState: VehicleGroupsState = {
  map: {},
};

const slice = createSlice({
  name: 'vehicle_groups',
  initialState,
  reducers: {
    setMap(
      state: VehicleGroupsState,
      action: PayloadAction<VehicleGroupMapShape>,
    ) {
      state.map = action.payload;
    },
  },
});

export const { setMap: setVehicleGroupsMap } = slice.actions;

export const VehicleGroupReducer = slice.reducer;
