import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AccessTerminalConnectionFormModal,
  AccessTerminalFormData,
  AccessTerminalShape,
  AccessTerminalsApiResponse,
} from 'models/Facilities';

import { baseQuery } from '../..';

export type PostType = {
  url: string;
  payload: AccessTerminalFormData | AccessTerminalConnectionFormModal;
};

export const AccessTerminalApi = createApi({
  reducerPath: 'accessTerminals',
  tagTypes: ['access-terminals'],
  baseQuery,
  endpoints: (builder) => ({
    getAccessTerminals: builder.query<AccessTerminalsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['access-terminals'],
    }),
    getAccessTerminal: builder.query<AccessTerminalShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['access-terminals'],
    }),
    addAccessTerminals: builder.mutation<AccessTerminalsApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['access-terminals'],
    }),
    assignConnectionToAccessTerminal: builder.mutation<
      AccessTerminalsApiResponse,
      PostType
    >({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['access-terminals'],
    }),
  }),
});

export const {
  useGetAccessTerminalsQuery,
  useGetAccessTerminalQuery,
  useAddAccessTerminalsMutation,
  useAssignConnectionToAccessTerminalMutation,
} = AccessTerminalApi;
