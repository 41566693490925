import { FC, useState, useEffect } from 'react';
import { AccountCircleIcon } from '../../SearchBox.Icons';
import { SearchBoxItem } from '../../SearchBox.Item';
import { useGetSearchResultsQuery } from 'store/_Api/Search';
import { ContractShape } from 'models/EndUsers';
import { SearchBoxItemGroup } from 'components/_shared/Search/SearchBox.ItemGroup';

interface Props {
  searchUrl: string;
  setSectionState: (
    isLoading: boolean,
    hasResults: boolean | undefined,
  ) => void;
}

const ContractSearchResults: FC<Props> = ({
  searchUrl,
  setSectionState,
}): JSX.Element => {
  const [contactList, setContactList] = useState<ContractShape[]>([]);

  const { data, isLoading } = useGetSearchResultsQuery(searchUrl);

  useEffect(() => {
    if (data) {
      const items = data.Items as ContractShape[];
      setContactList(items);
      setSectionState(isLoading, items.length > 0);
    } else {
      setSectionState(isLoading, undefined);
    }
  }, [data, isLoading, setSectionState]);

  if (isLoading || contactList.length === 0) {
    return <></>;
  }

  return (
    <SearchBoxItemGroup title="Contracts">
      {contactList.map((contract, index) => (
        <SearchBoxItem
          key={index}
          text={contract.OperatorName}
          icon={<AccountCircleIcon />}
        />
      ))}
    </SearchBoxItemGroup>
  );
};

export default ContractSearchResults;
