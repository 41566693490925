import CustomerVehicleModal from 'components/EndUser/Modal/Vehicle';
import { SearchBoxItemGroup } from 'components/_shared/Search/SearchBox.ItemGroup';
import { VehicleShape } from 'models/EndUsers';
import { FC, useEffect, useState } from 'react';
import { useGetSearchResultsQuery } from 'store/_Api/Search';
import { DirectionsCarFilledIcon } from '../../SearchBox.Icons';
import { SearchBoxItem } from '../../SearchBox.Item';

interface Props {
  searchUrl: string;
  setSectionState: (
    isLoading: boolean,
    hasResults: boolean | undefined,
  ) => void;
}

const VehicleSearchResults: FC<Props> = ({
  searchUrl,
  setSectionState,
}): JSX.Element => {
  const [viewVehicle, setViewVehicle] = useState<VehicleShape | undefined>(
    undefined,
  );

  const [list, setList] = useState<VehicleShape[]>([]);

  const { data, isLoading } = useGetSearchResultsQuery(searchUrl);

  useEffect(() => {
    const items = data?.Items as undefined | VehicleShape[];
    setList(items ?? []);

    setSectionState(isLoading, items ? items.length > 0 : undefined);
  }, [setSectionState, data, isLoading, searchUrl]);

  const viewVehicleModal = (vehicle: VehicleShape) => {
    setViewVehicle(vehicle);
  };

  if (isLoading || list.length === 0) {
    return <></>;
  }

  return (
    <SearchBoxItemGroup title="Vehicles">
      {list.map((vehicle, index) => (
        <div key={index}>
          <SearchBoxItem
            viewVehicleModal={() => viewVehicleModal(vehicle)}
            text={vehicle.LicensePlate}
            icon={<DirectionsCarFilledIcon />}
          />

          {viewVehicle && (
            <CustomerVehicleModal
              vehicle={viewVehicle}
              handleClose={() => setViewVehicle(undefined)}
            />
          )}
        </div>
      ))}
    </SearchBoxItemGroup>
  );
};

export default VehicleSearchResults;
