import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { UserApiLoginPostData } from 'Api/User/UserApi';
import Text from 'components/_shared/Text';
import { Formik } from 'formik';
import useRefMounted from 'hooks/useRefMounted';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useAuth from 'store/Auth/useAuth';
import * as Yup from 'yup';
import FormLayout from '../Common/FormLayout';

const LoginPage: FC = () => {
  const { login } = useAuth();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const isMountedRef = useRefMounted();
  const location = useLocation();

  const initialValues: UserApiLoginPostData = {
    username: '',
    password: '',
    rememberMe: false,
  };

  return (
    <>
      <FormLayout
        title={t('Sign in')}
        subTitle={t('Fill in the fields below to sign into your account.')}
      >
        {!!error.length && (
          <Alert style={{ marginBottom: 10 }} severity="error">
            {error.length > 1 && error}
          </Alert>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            username: Yup.string()
              .email(t('The email provided should be a valid email address'))
              .max(255)
              .required(t('The email field is required')),
            password: Yup.string()
              .max(255)
              .required(t('The password field is required')),
            rememberMe: Yup.boolean().oneOf([true, false]),
          })}
          onSubmit={async (
            values,
            { setStatus, setSubmitting },
          ): Promise<void> => {
            setSubmitting(true);
            await login(values)
              .then(() => {
                if (isMountedRef.current) {
                  setStatus({ success: true });
                }
              })
              .catch((error) => {
                let errorMessage = 'Something went wrong';
                if (
                  (error?.data['Errors'][''][0] ?? '').includes(
                    'Invalid email or password',
                  )
                ) {
                  errorMessage = 'Invalid email or password';
                }
                setError(errorMessage);
                setSubmitting(false);
                setStatus({ success: false });
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }): JSX.Element => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                {location.state && location.state.passwordUpdateMessage && (
                  <Container
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                    maxWidth="sm"
                  >
                    <Box textAlign="center">
                      <Text flex={false} color="success" className="success">
                        {location.state.passwordUpdateMessage}
                      </Text>
                    </Box>
                  </Container>
                )}

                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  margin="normal"
                  autoFocus
                  helperText={touched.username && errors.username}
                  label={t('Email address')}
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  margin="normal"
                  helperText={touched.password && errors.password}
                  label={t('Password')}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.rememberMe}
                        name="rememberMe"
                        color="primary"
                        onChange={handleChange}
                      />
                    }
                    label={
                      <>
                        <Typography variant="body2">
                          <Link component="a">{t('Remember me')}</Link>
                        </Typography>
                      </>
                    }
                  />
                  <Link component={RouterLink} to="/forgot-password">
                    <b>{t('Lost password?')}</b>
                  </Link>
                </Box>

                <Button
                  sx={{
                    mt: 3,
                  }}
                  color="primary"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  {t('Sign in')}
                </Button>
              </form>
            );
          }}
        </Formik>
      </FormLayout>
    </>
  );
};

export default LoginPage;
