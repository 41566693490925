import { RouteObject } from 'react-router-dom';
import Loader from 'router/Loader';
import { lazy } from 'react';

const ForgotPasswordPage = Loader(
  lazy(() => import('content/pages/Guest/ForgotPassword')),
);
const ResetPasswordPage = Loader(
  lazy(() => import('content/pages/Guest/ResetPassword')),
);

const PasswordResetRouter: RouteObject[] = [
  {
    path: 'forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
  },
];

export default PasswordResetRouter;
