import { LinkShape } from 'models/Link';
import { AnalyticsFacility } from 'models/Analytics';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { useGetAnalyticsQuery } from 'store/_Api/Analytics';
import { setAnalyticsDates, setFacilities, setLinks } from './AnalyticsSlice';
import useAnalyticsLinks from './useAnalyticsLinks';

type Dates = { from: string; to: string };
type Return = {
  facilities: AnalyticsFacility[] | undefined;
  links: LinkShape[] | undefined;
  dates: Dates;
  setDates: (dates: Dates) => void;
  avgOccupancy: number | undefined;
};

const useAnalytics = (): Return => {
  const { facilities, links, dates } = useSelector((state) => state.analytics);
  const dispatch = useDispatch();

  const { baseLink } = useAnalyticsLinks();
  const [link, setLink] = useState(baseLink);
  const [avgOccupancy, setAvgOccupancy] = useState<number | undefined>();

  useEffect(() => {
    setLink(baseLink);
  }, [baseLink]);
  const { data: baseAnalyticsData } = useGetAnalyticsQuery(link, {
    skip: !link,
  });

  useEffect(() => {
    if (baseAnalyticsData) {
      const { Facilities, Links, AvgOccupancy } = baseAnalyticsData;
      setAvgOccupancy(AvgOccupancy);
      dispatch(setFacilities(Facilities));
      dispatch(setLinks(Links));
    }
  }, [baseAnalyticsData, dispatch]);

  const setDates = (dates: Dates) => {
    dispatch(setAnalyticsDates(dates));
  };

  return {
    facilities,
    links,
    dates,
    setDates,
    avgOccupancy,
  };
};

export default useAnalytics;
