import {
  alpha,
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LogOutIcon, SearchInMenuIcon } from 'atoms/Icons';
import { SearchBoxDialog } from 'components/_shared/Search';
import { useDispatch, useSelector } from 'store';
import { setIsOpened as setIsSearchOpened } from 'store/slices/Search';

import ConfirmationModal from 'components/_shared/Modal/Confirmation';
import { useBaseLinks } from 'store/Auth';
import useAuth from 'store/Auth/useAuth';
import { setShowConfirmation } from 'store/slices/Logout';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

const SidebarFooter = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getLink } = useBaseLinks();
  const { logout } = useAuth();

  const dispatch = useDispatch();
  const hasFooterSearch = getLink('search');
  const { showConfirmation } = useSelector((state) => state.Logout);
  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      dispatch(setShowConfirmation(false));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        height: 60,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LightTooltip placement="top" arrow title={t('Logout').toString()}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[10]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`,
            },
          }}
          onClick={() => dispatch(setShowConfirmation(true))}
        >
          <LogOutIcon height="0.8em" width="0.8em" opacity="0.8" />
        </IconButton>
      </LightTooltip>
      {hasFooterSearch && (
        <LightTooltip
          placement="top"
          style={{ margin: 10 }}
          arrow
          title={t(
            'Search for license plate, user, transaction etc.',
          ).toString()}
        >
          <IconButton
            sx={{
              background: `${theme.colors.alpha.trueWhite[10]}`,
              color: `${theme.colors.alpha.trueWhite[70]}`,
              transition: `${theme.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`,
              },
            }}
            onClick={() => dispatch(setIsSearchOpened(true))}
          >
            <SearchInMenuIcon height="0.8em" width="0.8em" opacity="0.8" />
          </IconButton>
        </LightTooltip>
      )}

      <SearchBoxDialog />
      {showConfirmation && (
        <ConfirmationModal
          onClose={() => dispatch(setShowConfirmation(false))}
          onConfirm={handleLogout}
          title="Logout"
          text={t('Are you sure you want to logout?')}
        />
      )}
    </Box>
  );
};

export default SidebarFooter;
