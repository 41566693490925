import { RouteObject } from 'react-router-dom';
import Loader from 'router/Loader';
import { lazy } from 'react';

const SkipMenuFiltering = Loader(
  lazy(() => import('content/pages/Private/SkipMenuFiltering')),
);

const SkipMenuFilteringRoute: RouteObject[] = [
  {
    path: 'secret',
    element: <SkipMenuFiltering />,
  },
];

export default SkipMenuFilteringRoute;
