import { Box, Grid, styled } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CalendarIcon from 'content/layouts/PrivateLayout/Sidebar/CustomCalendar';
import WojtekmajyDateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { CSSProperties, FC, useEffect, useState, useCallback } from 'react';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './DateTimeRangePicker.scss';

import { LooseValue } from '@wojtekmaj/react-datetimerange-picker/dist/cjs/shared/types';

const DateTimeRangePickerWrapper = styled(WojtekmajyDateTimeRangePicker)(
  ({ theme }) => `
    .react-datetimerange-picker__wrapper {     
      color: rgb(34, 51, 84);
      border: 1px solid rgba(0, 0, 0, 0.23) !important;      
      border-radius: 10px;
      margin-bottom:4px;           
      .react-datetimerange-picker__inputGroup__input:invalid {
        background:none;
      }
      .react-datetimerange-picker__inputGroup{
        font-weight: bold;
        padding: 0px ${theme.spacing(1)};
      }
      .react-datetimerange-picker--open {
        position : absolute;
      }
      .react-datetimerange-picker--closed {
        position : absolute;
      }     
      .react-datetimerange-picker__button {
        padding: 4px 0px;
      }     
      .react-datetimerange-picker__clear-button {
        display:none;
      }
    }`,
);

interface Props {
  defaultDateRange: string | Date | Date[];
  handleChange: (v: string | Date | Date[]) => void;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  disablePastDates?: boolean;
}

const DateTimeRangePicker: FC<Props> = ({
  defaultDateRange,
  handleChange,
  placeholder,
  style,
  disabled = false,
  disablePastDates = false,
}): JSX.Element => {
  const [floatingLabelClass, setFloatingLabelClass] = useState('');
  const [isOpenCalender, setIsOpenCalender] = useState(false);
  const [dateTimeRange, setDateTimeRange] = useState<LooseValue>();

  const tileDisabled = useCallback(
    ({ date, view }: { date: Date; view: string }) => {
      if (view === 'month' && disablePastDates) {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
      }
      return false;
    },
    [disablePastDates],
  );
  useEffect(() => {
    if (dateTimeRange) {
      handleChange(JSON.parse(JSON.stringify(dateTimeRange)));
    } else {
      handleChange('');
      setFloatingLabelClass('');
    }
  }, [dateTimeRange, handleChange]);

  return (
    <Box
      style={style}
      className={`date-time_range_picker ${disabled ? 'disabled' : ''}`}
    >
      <label
        onClick={() => {
          if (!disabled) {
            setIsOpenCalender(true);
            setFloatingLabelClass('float_label');
          }
        }}
        className={floatingLabelClass}
      >
        {placeholder}
      </label>
      <DateTimeRangePickerWrapper
        disabled={Boolean(disabled)}
        isCalendarOpen={isOpenCalender}
        onFocus={() => {
          if (!disabled) {
            setFloatingLabelClass('float_label');
            setIsOpenCalender(true);
          }
        }}
        onCalendarClose={() => {
          setIsOpenCalender(false);
        }}
        onChange={setDateTimeRange}
        value={dateTimeRange}
        calendarIcon={
          <Grid container alignItems="center">
            {defaultDateRange && (
              <CloseIcon onClick={() => setDateTimeRange('')} fill={'black'} />
            )}
            <CalendarIcon fill={'black'} />
          </Grid>
        }
        sx={{ p: 2 }}
        format={'dd-MM-yyyy HH:mm'}
        disableClock
        minDetail="month"
        tileDisabled={tileDisabled}
      />
    </Box>
  );
};

export default DateTimeRangePicker;
