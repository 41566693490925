import defaultAxios from 'axios';

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response?.data ?? 'No response data';
    return Promise.reject(response);
  },
);

export default axios;
