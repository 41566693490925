import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '..';
import {
  MembershipsSubsciptionsApiResponse,
  // MembershipSubscription,
  SubsciptionsApiResponse,
} from 'models/Subscriptions';

export const SubscriptionsApi = createApi({
  reducerPath: 'subscription',
  tagTypes: ['subscriptions', 'membership-subscriptions'],
  baseQuery,
  endpoints: (builder) => ({
    getSubscription: builder.query<SubsciptionsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['subscriptions'],
    }),
    getMembershipSubscriptions: builder.query<
      MembershipsSubsciptionsApiResponse,
      string
    >({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['membership-subscriptions'],
    }),
  }),
});

export const { useGetSubscriptionQuery, useGetMembershipSubscriptionsQuery } =
  SubscriptionsApi;
