export interface PureLightThemePadding {
  none: number;
  xTiny: number;
  tiny: number;
  xSmall: number;
  small: number;
}
export const padding: PureLightThemePadding = {
  none: 0,
  xTiny: 0.6,
  tiny: 2,
  xSmall: 3,
  small: 5,
};
