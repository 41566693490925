import { useState, useEffect, FC } from 'react';
import { Card } from '@mui/material';

import { TransactionShape, EndUserShape } from 'models/EndUsers';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import EndUsersTransactionsList from 'components/EndUser/Modal/Tab/Transactions/List/EndUserTransactionsList';
import { useTransactionListQuery } from 'store/_Api/Users';

interface Props {
  user: EndUserShape;
}

const EndUserTransactionsTab: FC<Props> = ({ user }): JSX.Element => {
  const [list, setList] = useState<TransactionShape[]>([]);
  const [hasNextLink, setHasNextLink] = useState(true);
  const transactionUrl = getApiHrefByRel(user?.Links, 'transactions');
  const [link, setLink] = useState('');

  useEffect(() => {
    if (transactionUrl) {
      setLink(transactionUrl);
    }
  }, [transactionUrl]);

  const { data } = useTransactionListQuery(link, { skip: !link });
  const transactionListData = data?.Items;
  const listActionLinks = data?.Links;

  const onScroll = () => {
    if (listActionLinks) {
      const nextLink = getApiHrefByRel(listActionLinks, 'next');
      if (nextLink && hasNextLink) {
        setLink(nextLink);
      } else {
        setHasNextLink(false);
      }
    }
  };

  useEffect(() => {
    if (transactionListData) {
      setList((prevState) => [...prevState, ...transactionListData]);
      return;
    }
  }, [transactionListData, data, link]);

  return (
    <Card sx={{ width: '100%' }}>
      <EndUsersTransactionsList list={list} handleFetchList={onScroll} />
    </Card>
  );
};

export default EndUserTransactionsTab;
