import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AssignCameraToLaneModel,
  CameraConnectionFormModel,
  CameraFormModel,
  CamerasApiResponse,
  CameraShape,
} from 'models/Facilities';
import { baseQuery } from '../..';
import { AddExitLaneModel } from 'models/Facilities';

export type PostType = {
  url: string;
  payload:
    | CameraFormModel
    | AddExitLaneModel
    | AssignCameraToLaneModel
    | CameraConnectionFormModel;
};

export const CamerasApi = createApi({
  reducerPath: 'cameras',
  tagTypes: ['cameras', 'camera', 'entry-lanes', 'exit-lane'],
  baseQuery,
  endpoints: (builder) => ({
    getCameras: builder.query<CamerasApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['cameras'],
    }),
    getCamera: builder.query<CameraShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['camera'],
    }),

    RegisterCamera: builder.mutation<CamerasApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['cameras'],
    }),
    updateCameraConnection: builder.mutation<CamerasApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['cameras'],
    }),
  }),
});

export const {
  useGetCamerasQuery,
  useGetCameraQuery,
  useRegisterCameraMutation,
  useUpdateCameraConnectionMutation,
} = CamerasApi;
