import { FC } from 'react';
import { styled, TableCell, Tooltip, Typography } from '@mui/material';

interface Props {
  title: string | number;
  customStyle?: boolean;
  textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

const StyleTableCell = styled(TableCell)(() => ({
  maxWidth: '170px',
}));

const StyledTableCell: FC<Props> = ({
  title,
  customStyle,
  textAlign,
}): JSX.Element => {
  if (customStyle) {
    return (
      <TableCell>
        <Typography
          variant="body1"
          color="text.primary"
          gutterBottom
          sx={{ width: '150px' }}
          align={textAlign}
        >
          {title}
        </Typography>
      </TableCell>
    );
  }
  return (
    <Tooltip title={title}>
      <StyleTableCell>
        <Typography
          variant="body1"
          color="text.primary"
          align={textAlign}
          gutterBottom
          noWrap
        >
          {title}
        </Typography>
      </StyleTableCell>
    </Tooltip>
  );
};

export default StyledTableCell;
