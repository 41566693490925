import { FC, useState, useEffect } from 'react';
import { AccountCircleIcon } from '../../SearchBox.Icons';
import { SearchBoxItem } from '../../SearchBox.Item';
import { TransactionShape } from 'models/EndUsers';
import CustomerTransactionModal from 'components/EndUser/Modal/Transaction';
import { useGetSearchResultsQuery } from 'store/_Api/Search';
import { SearchBoxItemGroup } from 'components/_shared/Search/SearchBox.ItemGroup';

interface Props {
  searchUrl: string;
  setSectionState: (
    isLoading: boolean,
    hasResults: boolean | undefined,
  ) => void;
}

const TransactionSearchResults: FC<Props> = ({
  searchUrl,
  setSectionState,
}): JSX.Element => {
  const [viewTransaction, setViewTransaction] = useState<
    TransactionShape | undefined
  >(undefined);

  const [list, setList] = useState<TransactionShape[]>([]);

  const { data, isLoading } = useGetSearchResultsQuery(searchUrl);

  useEffect(() => {
    if (data) {
      const items = data.Items as TransactionShape[];
      setList(items);
      setSectionState(isLoading, items.length > 0);
    } else {
      setSectionState(isLoading, undefined);
    }
  }, [data, setSectionState, isLoading]);

  const viewTransactionModel = (transaction: TransactionShape) => {
    setViewTransaction(transaction);
  };

  if (isLoading || list.length === 0) {
    return <></>;
  }

  return (
    <SearchBoxItemGroup title="Transactions">
      {list.map((transaction, index) => (
        <div key={index}>
          <SearchBoxItem
            viewTransactionModal={() => viewTransactionModel(transaction)}
            text={transaction.Operator}
            icon={<AccountCircleIcon />}
          />
          {viewTransaction && (
            <CustomerTransactionModal
              transaction={viewTransaction}
              handleClose={() => setViewTransaction(undefined)}
            />
          )}
        </div>
      ))}
    </SearchBoxItemGroup>
  );
};

export default TransactionSearchResults;
