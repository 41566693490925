import { RouteObject } from 'react-router';
import Authenticated from 'components/_shared/Authenticated';
import PrivateLayout from 'content/layouts/PrivateLayout';
import Loader from 'router/Loader';
import { lazy } from 'react';

import overviewRouter from './Overview';
import configurationRouter from './Configuration';
import managementRouter from './Management/managementRouter';
import SkipMenuFilteringRoute from './SkipMenuFiltering/SkipMenuFilteringRouting';

const ComingSoon = Loader(
  lazy(() => import('content/pages/Private/ComingSoon')),
);

const privateRouter: RouteObject = {
  element: (
    <Authenticated>
      <PrivateLayout />
    </Authenticated>
  ),
  children: [
    ...overviewRouter,
    ...configurationRouter,
    ...managementRouter,
    ...SkipMenuFilteringRoute,
    { path: 'coming-soon', element: <ComingSoon /> },
  ],
};

export default privateRouter;
