import Api from 'Api';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import EndUsersApi from 'Api/EndUsers';
import EndUserModal from 'components/EndUser/Modal';
import { SearchBoxItemGroup } from 'components/_shared/Search/SearchBox.ItemGroup';
import { EndUserShape } from 'models/EndUsers';
import { FC, useEffect, useState } from 'react';
import { useGetSearchResultsQuery } from 'store/_Api/Search';
import { AccountCircleIcon } from '../../SearchBox.Icons';
import { SearchBoxItem } from '../../SearchBox.Item';

interface Props {
  searchUrl: string;
  type: 'customers' | string;
  setSectionState: (
    isLoading: boolean,
    hasResults: boolean | undefined,
  ) => void;
}

const EndUserSearchResults: FC<Props> = ({
  searchUrl,
  type,
  setSectionState,
}): JSX.Element => {
  const [viewUser, setViewUser] = useState<EndUserShape | undefined>(undefined);
  const [list, setList] = useState<EndUserShape[]>([]);

  const { data, isLoading } = useGetSearchResultsQuery(searchUrl);

  useEffect(() => {
    const items = data?.Items as EndUserShape[] | undefined;
    setList(items ?? []);

    setSectionState(isLoading, items ? items.length > 0 : undefined);
  }, [data, isLoading, setSectionState, searchUrl]);

  const viewSelectedUser = async (user: EndUserShape) => {
    const selfUrl = getApiHrefByRel(user.Links, 'self');
    if (selfUrl) {
      const response = await Api.get(selfUrl);
      if (response) {
        setViewUser(response.data);
      }
    }
  };

  const handleDelete = async (user: EndUserShape) => {
    if (user) {
      const deleteLink = getApiHrefByRel(user.Links, 'delete');
      if (deleteLink) {
        try {
          await EndUsersApi.deleteUserByID(deleteLink);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  if (isLoading || list.length === 0) {
    return <></>;
  }

  return (
    <SearchBoxItemGroup title={type === 'customers' ? 'Customers' : 'Users'}>
      {list.map((user, index) => (
        <div key={index}>
          <SearchBoxItem
            text={user.Name}
            secondaryText={user.Email}
            setViewUser={() => viewSelectedUser(user)}
            icon={<AccountCircleIcon />}
          />
          {viewUser && (
            <EndUserModal
              handleClose={() => setViewUser(undefined)}
              user={viewUser}
              handleDelete={(user) => handleDelete(user)}
            />
          )}
        </div>
      ))}
    </SearchBoxItemGroup>
  );
};

export default EndUserSearchResults;
