import { RouteObject } from 'react-router';
import privateRouter from './Private';
import guestRouter from './Guest';
import commonRouter from './Common';

const router: RouteObject[] = [
  {
    ...guestRouter,
  },
  {
    ...privateRouter,
  },
  {
    ...commonRouter,
  },
];

export default router;
