import { FC } from 'react';
// import useRefMounted from 'hooks/useRefMounted';

import { Typography } from '@mui/material';
import { VehicleShape } from 'models/EndUsers';

interface Props {
  vehicleData: VehicleShape;
}

const DeleteDevice: FC<Props> = ({ vehicleData }) => {
  return (
    <Typography variant="subtitle1" gutterBottom component="div" sx={{ p: 4 }}>
      Are you sure you want to delete {vehicleData.VehicleGuid}
    </Typography>
  );
};

export default DeleteDevice;
