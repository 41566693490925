import { SearchBoxItemGroup } from '../../SearchBox.ItemGroup';
import {
  HistoryIcon,
  FilledStarIcon,
  EmptyStarIcon,
} from '../../SearchBox.Icons';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { SearchHistoryItemShape } from 'models/Search';
import { useEffect, useState, FC } from 'react';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import SearchHistoryItem from '../Item';
import { useBaseLinks } from 'store/Auth';
import { useGetSearchHistoryQuery } from 'store/_Api/Search';
import useSearchActions from 'store/Search/useSearchActions';
import { skipToken } from '@reduxjs/toolkit/dist/query';

type Props = {
  handleSelectItem: (item: SearchHistoryItemShape) => void;
  limit?: number;
};

const RecentSearches: FC<Props> = ({ handleSelectItem, limit = 4 }) => {
  const { save: saveItem, unSave: unSaveItem, remove } = useSearchActions();

  const { getLink } = useBaseLinks();
  const recentSearchUrl = getLink('search-history');
  const [recentSearchLink, setSearchLink] = useState(recentSearchUrl);
  useEffect(() => {
    setSearchLink(recentSearchUrl);
  }, [recentSearchUrl]);

  const { data } = useGetSearchHistoryQuery(recentSearchLink ?? skipToken);

  const items = data?.Items as SearchHistoryItemShape[] | undefined;

  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <>
      <SearchBoxItemGroup title="Recent searches">
        {items.slice(0, limit).map((item, index: number) => (
          <SearchHistoryItem
            key={index}
            data={item}
            handleSelect={() => handleSelectItem(item)}
            icon={<HistoryIcon />}
            actions={
              <Box>
                {getApiHrefByRel(item.Links, 'save') ? (
                  /**
                   * If there is a save link on the item, it means that this item is not saved,
                   * so it we show an empty star
                   */
                  <IconButton size="small" onClick={() => saveItem(item)}>
                    <EmptyStarIcon />
                  </IconButton>
                ) : (
                  /**
                   * else, this is a saved search item,
                   * so we show filled start
                   */
                  <IconButton size="small" onClick={() => unSaveItem(item)}>
                    <FilledStarIcon />
                  </IconButton>
                )}
                <IconButton size="small" onClick={() => remove(item)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            }
          />
        ))}
      </SearchBoxItemGroup>
    </>
  );
};

export default RecentSearches;
