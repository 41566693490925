import { RouteObject } from 'react-router-dom';
import Loader from 'router/Loader';
import { lazy } from 'react';
import BaseLayout from 'content/layouts/BaseLayout';

const Facilities = Loader(
  lazy(() => import('content/pages/Private/Facilities')),
);
const FacilityView = Loader(
  lazy(() => import('content/pages/Private/Facilities/FacilityView')),
);

const OldFacilitiesPage = Loader(
  lazy(() => import('content/pages/Private/OldFacilities')),
);
const OldFacilityView = Loader(
  lazy(() => import('content/pages/Private//OldFacilities/OldFacilityView')),
);

const CarParksPage = Loader(
  lazy(() => import('content/pages/Private/Facilities/CarPark')),
);

const FacilitiesRouter: RouteObject[] = [
  {
    path: '/facilities',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Facilities />,
      },
      {
        path: ':facilityGuid',
        element: <FacilityView />,
      },
      {
        path: ':facilityGuid/:carParkGuid',
        element: <CarParksPage />,
      },
    ],
  },
  {
    path: '/old-facilities',
    element: <OldFacilitiesPage />,
  },
  {
    path: '/old-facilities/:id',
    element: <OldFacilityView />,
  },
];

export default FacilitiesRouter;
