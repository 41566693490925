import type { ReactNode } from 'react';
import {
  AnalyticsIcon,
  DashboardIcon,
  FinancialIcon,
  MonitoringIcon,
  CameraFeedIcon,
  FacilitiesPIcon,
  PricingIcon,
  UsersIcon,
  MatchingIcon,
  ParkingSessionsIcon,
  FAQIcon,
  SupportIcon,
  DocumentationIcon,
  EventLogIcon,
  TeamPageIcon,
  AllowListPageIcon,
  SubscriptionIcon,
  CarIcon,
} from 'atoms/Icons';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
/**
 * Link MUST be defined either on an icon or on a sub-icon
 */
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name:
    | 'Dashboard'
    | 'Analytics'
    | 'Financial'
    | 'Monitoring'
    | 'Camera feed'
    | 'Facilities'
    | 'Memberships'
    | 'Pricing'
    | 'Matching'
    | 'Parking sessions'
    | 'Facility access'
    | 'Event log'
    | 'Subscriptions'
    | 'Customers'
    | 'End-Users'
    | 'Team'
    | 'FAQ'
    | 'Support'
    | 'Documentation'
    | 'Operators'
    | 'ID-carriers'
    | 'System'
    | 'Users'
    | 'SubOperators'
    | 'Vehicle groups'
    | 'Flagged parkings';
  id: string;
}

export interface MenuSection {
  items: MenuItem[];
  heading: 'Overview' | 'Management' | 'Help' | 'Configuration' | 'End-Users';
}

const sidebarMenuItems: MenuSection[] = [
  {
    heading: 'Overview',
    items: [
      {
        id: 'dashboard',
        name: 'Dashboard',
        icon: <DashboardIcon />,
        link: '/dashboard',
      },
      {
        id: 'analytics',
        name: 'Analytics',
        icon: <AnalyticsIcon />,
        link: '/analytics',
      },
      {
        id: 'financial',
        name: 'Financial',
        icon: <FinancialIcon />,
        link: '/financial',
      },
    ],
  },
  {
    heading: 'Management',
    items: [
      {
        id: 'monitoring',
        name: 'Monitoring',
        link: '/monitoring',
        icon: <MonitoringIcon />,
      },
      {
        id: 'camera-feed',
        name: 'Camera feed',
        link: '/camera-feed',
        icon: <CameraFeedIcon />,
      },
      {
        id: 'facilities',
        name: 'Facilities',
        link: '/facilities',
        icon: <FacilitiesPIcon />,
      },
      {
        id: 'subscription-plans',
        name: 'Subscriptions',
        link: '/subscription-plans',
        icon: <SubscriptionIcon />,
      },
      {
        id: 'facility-memberships',
        name: 'Memberships',
        link: '/memberships',
        icon: <AllowListPageIcon />,
      },
      {
        id: 'vehicle-groups',
        name: 'Vehicle groups',
        link: '/vehicle-groups',
        icon: <CarIcon />,
      },
      {
        id: 'pricing',
        name: 'Pricing',
        link: '/pricing',
        icon: <PricingIcon />,
      },

      {
        id: 'flagged-vehicles',
        name: 'Matching',
        link: '/matching',
        icon: <MatchingIcon />,
      },
      {
        id: 'parkings',
        name: 'Parking sessions',
        link: '/parkings',
        icon: <ParkingSessionsIcon />,
      },
      {
        id: 'parkings-requiring-attention',
        name: 'Flagged parkings',
        link: '/parkings-requiring-attention',
        icon: <ErrorOutlineIcon />,
      },
      {
        id: 'allowlists',
        name: 'Facility access',
        link: '/facility-access',
        icon: <AllowListPageIcon />,
      },
      {
        id: 'event-log',
        name: 'Event log',
        link: '/event-log',
        icon: <EventLogIcon />,
      },

      {
        id: 'customers',
        name: 'Customers',
        icon: <UsersIcon />,
        link: '/customers',
      },
      {
        id: 'end-users',
        name: 'End-Users',
        link: '/end-users',
        icon: <UsersIcon />,
      },
      {
        id: 'team',
        name: 'Team',
        link: '/team',
        icon: <TeamPageIcon />,
      },
    ],
  },
  {
    heading: 'Help',
    items: [
      { id: 'faq', name: 'FAQ', link: '/coming-soon', icon: <FAQIcon /> },
      {
        id: 'support',
        name: 'Support',
        link: '/coming-soon',
        icon: <SupportIcon />,
      },
      {
        id: 'documentation',
        name: 'Documentation',
        link: '/coming-soon',
        icon: <DocumentationIcon />,
      },
    ],
  },
  {
    heading: 'Configuration',
    items: [
      {
        id: 'operator-list',
        name: 'Operators',
        link: '/operators',
        badge: '',
        badgeTooltip: 'Configuration - Operators',
      },
      {
        id: 'id-carriers',
        name: 'ID-carriers',
        link: '/id-carriers',
        badge: '',
        badgeTooltip: 'Configuration - ID-Carriers',
      },
      {
        id: 'system',
        name: 'System',
        link: '/system',
        badge: '',
        badgeTooltip: 'Configuration - System',
      },
      {
        id: 'users',
        name: 'Users',
        link: '/users',
        badge: '',
        badgeTooltip: 'Configuration - Users',
      },
    ],
  },
  {
    heading: 'End-Users',
    items: [
      {
        id: 'sub-operators',
        name: 'SubOperators',
        link: '/suboperators',
        badge: '',
        badgeTooltip: 'End-Users - suboperators',
      },
    ],
  },
];

export default sidebarMenuItems;
