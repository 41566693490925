import { LinkShape } from 'models/Link';
import { parseTemplate } from 'url-template';

export const getApiLinkByRel = (
  links: LinkShape[],
  apiRel: string,
): LinkShape | undefined =>
  links && links.find((item) => item.Rel === apiRel && item.Href);

export const getSearchLink = (url: string, query: string): string => {
  const urlTemplate = parseTemplate(decodeURI(url));
  const updatedUrl = urlTemplate.expand({
    q: query.toLowerCase(),
  });
  return updatedUrl;
};

export const getApiHrefByRel = (
  links: LinkShape[],
  apiRel: string,
): string | undefined => {
  const link = getApiLinkByRel(links, apiRel);
  return link?.Href;
};
