import Loader from 'router/Loader';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import BaseLayout from 'content/layouts/BaseLayout';

const Status404 = Loader(
  lazy(() => import('content/pages/Common/Status/Status404')),
);
const Status500 = Loader(
  lazy(() => import('content/pages/Common/Status/Status500')),
);

const StatusMaintenance = Loader(
  lazy(() => import('content/pages/Common/Status/Maintenance')),
);

const Error = Loader(lazy(() => import('content/pages/Common/Error')));

const commonRouter: RouteObject = {
  path: '*',
  element: <BaseLayout />,
  children: [
    {
      path: 'status',
      children: [
        {
          path: '500',
          element: <Status500 />,
        },
        {
          path: 'maintenance',
          element: <StatusMaintenance />,
        },
      ],
    },
    {
      path: 'error',
      element: <Error />,
    },
    {
      path: '*',
      element: <Status404 />,
    },
  ],
};

export default commonRouter;
