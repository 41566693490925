import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '..';
import { OperatorResponseItem } from 'models/Operator';

export const OperatorsApi = createApi({
  reducerPath: 'operators',
  tagTypes: ['operators'],
  baseQuery,
  endpoints: (builder) => ({
    getOperatorsLink: builder.query<OperatorResponseItem, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['operators'],
    }),
  }),
});

export const { useGetOperatorsLinkQuery } = OperatorsApi;
