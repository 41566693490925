import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CarParksAreaApiResponse,
  FacilitiesApiResponse,
  Facility,
  ImageUpdateApiResponse,
  ImageUploadModel,
  MembershipPlansApiResponse,
} from 'models/Facilities';
import { CarPark, CarParksApiResponse } from 'models/Facilities';

import { baseQuery } from '..';
import { FacilityMembershipApiResponse } from 'models/Facilities/FacilityMembership';

export type PostType = {
  url: string;
  payload: ImageUploadModel;
};

export const FacilitiesApi = createApi({
  reducerPath: 'facilities',
  tagTypes: [
    'facilities',
    'facility',
    'membership-plans',
    'facility-memberships',
    'car-parks',
  ],
  baseQuery,
  endpoints: (builder) => ({
    getFacilities: builder.query<FacilitiesApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['facilities'],
    }),
    getFacility: builder.query<Facility, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['facility'],
    }),
    getCarParks: builder.query<CarParksApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getCarParkAreas: builder.query<CarParksAreaApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['car-parks'],
    }),
    getCarPark: builder.query<CarPark, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getMembershipPlans: builder.query<MembershipPlansApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['membership-plans'],
    }),
    getFacilityMemberships: builder.query<
      FacilityMembershipApiResponse,
      string
    >({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['facility-memberships'],
    }),

    updateFacilityImageUri: builder.mutation<ImageUpdateApiResponse, PostType>({
      query({ url, payload }) {
        return {
          url: url,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['facilities', 'facility'],
    }),
  }),
});

export const {
  useGetFacilitiesQuery,
  useGetFacilityQuery,
  useGetCarParksQuery,
  useGetCarParkAreasQuery,
  useGetCarParkQuery,
  useUpdateFacilityImageUriMutation,
  useGetMembershipPlansQuery,
  useGetFacilityMembershipsQuery,
} = FacilitiesApi;
