import { useRoutes } from 'react-router-dom';
import router from 'router';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import { useBaseApiLinksQuery } from 'store/_Api/Auth';
import SuspenseLoader from 'components/_shared/SuspenseLoader';
import ErrorLoginPage from 'content/pages/Guest/Login/ErrorLoginPage';

import './App.scss';

const App = (): JSX.Element => {
  const { isLoading, isError } = useBaseApiLinksQuery();

  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <CssBaseline />
          {isLoading ? (
            <div className="app-suspense-loader">
              <SuspenseLoader />
            </div>
          ) : isError ? (
            <div>
              <ErrorLoginPage />
            </div>
          ) : (
            <>{content}</>
          )}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
