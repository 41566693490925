import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import no from './translations/no';
import fi from './translations/fi';
import se from './translations/se';

const resources = {
  no: { translation: no },
  fi: { translation: fi },
  se: { translation: se },
};

let language = window.localStorage.getItem('Language');
language = String(language ? language : navigator.language).substring(0, 2);

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    fallbackLng: language,
    keySeparator: '.',
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
    returnNull: true,
  });

export default i18n;
