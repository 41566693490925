import { AxiosResponse } from 'axios';
import {
  EndUserResponseShape,
  TransactionResponseShape,
  ContractShape,
  CarrierTypeShape,
  VehicleResponseShape,
  EndUserShape,
  SubscriptionResponseShape,
} from 'models/EndUsers';
import Api from '..';

const EndUsersApi = {
  list: (
    url: string,
  ): Promise<AxiosResponse<EndUserResponseShape | undefined>> => {
    return Api.get(url);
  },
  getUserByID: (
    url: string,
  ): Promise<AxiosResponse<EndUserShape | undefined>> => {
    return Api.get(url);
  },
  deleteUserByID: (
    url: string,
  ): Promise<AxiosResponse<EndUserShape | undefined>> => {
    return Api.delete(url);
  },
  contractList: (url: string): Promise<AxiosResponse<ContractShape[]>> =>
    Api.get(url),
  subscriptionList: (
    url: string,
  ): Promise<AxiosResponse<SubscriptionResponseShape | undefined>> =>
    Api.get(url),
  transactionList: (
    url: string,
  ): Promise<AxiosResponse<TransactionResponseShape | undefined>> =>
    Api.get(url),
  vehiclesList: (
    url: string,
  ): Promise<AxiosResponse<VehicleResponseShape | undefined>> => Api.get(url),
  idCarriersList: (url: string): Promise<AxiosResponse<CarrierTypeShape[]>> =>
    Api.get(url),
};

export default EndUsersApi;
