import { useState, useEffect, useCallback, FC } from 'react';
import useRefMounted from 'hooks/useRefMounted';
import { Card, Button, ButtonGroup } from '@mui/material';
import axios from '_utils/axios';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import PrintIcon from '@mui/icons-material/Print';
import { VehicleShape } from 'models/EndUsers';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';

interface Props {
  vehicleData: VehicleShape;
}

const QrCode: FC<Props> = ({ vehicleData }) => {
  const isMountedRef = useRefMounted();
  const [qrCodeData, setQRCodeData] = useState<VehicleShape | null>(null);

  const dataUrl = getApiHrefByRel(vehicleData?.Links, 'qr-code');

  const qrCode = qrCodeData && qrCodeData.Links[1].Href.split('v0/').at(-1);

  const getQRCodeList = useCallback(async () => {
    if (dataUrl) {
      try {
        const response = await axios.get(dataUrl);

        if (isMountedRef.current) {
          setQRCodeData(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [dataUrl, isMountedRef]);

  const getNewQRCode = useCallback(
    async (data: VehicleShape | null) => {
      if (dataUrl && data) {
        try {
          const response = await axios.post(dataUrl, data);
          if (isMountedRef.current) {
            setQRCodeData(response.data);
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    [dataUrl, isMountedRef],
  );

  const sendQRCodeEmail = useCallback(async () => {
    if (dataUrl) {
      try {
        const response = await axios.post(`${dataUrl}/emails`);
        if (isMountedRef.current) {
          console.log(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [dataUrl, isMountedRef]);

  useEffect(() => {
    getQRCodeList();
  }, [getQRCodeList]);

  return (
    <Card
      sx={{
        width: 400,
        p: 4,
        display: 'grid',
        justifyContent: 'center',
      }}
    >
      <img
        src={`https://admin.swappaccess.local:3000/api/${qrCode}`}
        alt="QR code"
        loading="lazy"
      />

      <Button
        variant="contained"
        onClick={() => getNewQRCode(qrCodeData)}
        sx={{ m: 2 }}
      >
        New QR Code
      </Button>
      <ButtonGroup sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          startIcon={<MarkunreadIcon />}
          onClick={sendQRCodeEmail}
        >
          Email
        </Button>
        <Button variant="contained" startIcon={<PrintIcon />}>
          Print
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export default QrCode;
