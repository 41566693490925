import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { startOfWeek } from 'date-fns';
import { AnalyticsFacility } from 'models/Analytics';
import { format } from 'date-fns';
import { LinksMap } from 'store/Auth/Links/useBaseLinks';
import { makeMapForLinks } from '_utils/links';
import { LinkShape } from 'models/Link';

export type PeriodType =
  | 'today'
  | 'yesterday'
  | 'this-week'
  | 'last-week'
  | 'this-month'
  | 'last-month';

type AnalyticsState = {
  facilities: AnalyticsFacility[] | undefined;
  selectedFacility: AnalyticsFacility | undefined;
  dates: {
    from: string;
    to: string;
  };
  period: PeriodType;
  links: LinkShape[] | undefined;
  linksMap: LinksMap | undefined;
};

const initialState: AnalyticsState = {
  facilities: undefined,
  selectedFacility: undefined,
  dates: {
    from: format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd'),
  },
  period: 'this-week',
  links: undefined,
  linksMap: undefined,
};

const AnalyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setFacilities(
      state: AnalyticsState,
      action: PayloadAction<AnalyticsFacility[]>,
    ) {
      state.facilities = action.payload;
    },
    setDates(
      state: AnalyticsState,
      action: PayloadAction<{ from: string; to: string }>,
    ) {
      state.dates = action.payload;
    },
    setPeriod(
      state: AnalyticsState,
      action: PayloadAction<{ period: PeriodType }>,
    ) {
      state.period = action.payload.period;
    },
    setLinks(
      state: AnalyticsState,
      action: PayloadAction<LinkShape[] | undefined>,
    ) {
      state.links = action.payload;
      state.linksMap = state.links ? makeMapForLinks(state.links) : undefined;
    },
  },
});

export const {
  setFacilities,
  setDates: setAnalyticsDates,
  setPeriod,
  setLinks,
} = AnalyticsSlice.actions;

export const AnalyticsReducer = AnalyticsSlice.reducer;

export default AnalyticsSlice;
