import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import Loader from 'router/Loader';
import { lazy } from 'react';

const Dashboard = Loader(lazy(() => import('content/pages/Private/Dashboard')));
const Analytics = Loader(lazy(() => import('content/pages/Private/Analytics')));
const Monitoring = Loader(
  lazy(() => import('content/pages/Private/Monitoring')),
);
const Financial = Loader(lazy(() => import('content/pages/Private/Financial')));

const overviewRouter: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/analytics',
    element: <Analytics />,
  },
  {
    path: '/financial',
    element: <Financial />,
  },
  {
    path: '/monitoring',
    element: <Monitoring />,
  },
];

export default overviewRouter;
