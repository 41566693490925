import { useState, ChangeEvent, FC, useEffect } from 'react';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Divider,
  InputAdornment,
  TextField,
  styled,
  Box,
  IconButton,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { TransactionShape } from 'models/EndUsers';
import EditIcon from 'assests/icons_svg/blue_edit.svg';

import StyledTableCell from 'atoms/components/StyledTableCell';
import EmptyTable from 'atoms/components/EmptyTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatTimeDate } from '_utils/date';
import SuspenseLoader from 'components/_shared/SuspenseLoader';

const TextfieldWrapper = styled(Box)();

type Props = {
  list: TransactionShape[];
  handleFetchList: () => void;
};
type searchAbleFields =
  | 'Operator'
  | 'CarPark'
  | 'ParkingAttribute'
  | 'LicensePlate';

const EndUserTransactionsList: FC<Props> = ({ list, handleFetchList }) => {
  const { t } = useTranslation();

  const [transactionList, setTransactionList] = useState<TransactionShape[]>(
    [],
  );

  const [search, setSearchValue] = useState('');

  useEffect(() => {
    setTransactionList(list);
  }, [list]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
    getFilteredData(event.target.value);
  };

  const getFilteredData = (searchKey: string) => {
    const lowercasedValue = searchKey.toLowerCase().trim();
    const searchAbleColumns: searchAbleFields[] = [
      'Operator',
      'ParkingAttribute',
      'CarPark',
      'LicensePlate',
    ];
    if (searchKey === '') {
      setTransactionList([]);
    } else {
      const filteredData = transactionList?.filter((user) => {
        let columnIndex = 0;
        while (columnIndex < searchAbleColumns.length) {
          const columnKey = searchAbleColumns[columnIndex];
          columnIndex++;
          if (
            user[columnKey] &&
            user[columnKey]?.toLowerCase().includes(lowercasedValue)
          ) {
            return true;
          }
        }
        return false;
      });
      setTransactionList(filteredData);
    }
  };

  return (
    <Card
      id="scrollableDiv"
      sx={{
        width: '100%',
        height: '300px',
        overflow: 'auto',
      }}
    >
      <TextfieldWrapper
        sx={{
          p: 2,
          px: 3,
          textAlign: 'start',
        }}
      >
        <TextField
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          placeholder={t('Search...')}
          onChange={handleSearchChange}
        />
      </TextfieldWrapper>

      {transactionList.length > 0 && (
        <>
          <TableContainer>
            <InfiniteScroll
              dataLength={transactionList.length}
              next={handleFetchList}
              hasMore={true}
              loader={
                list.length !== transactionList.length && <SuspenseLoader />
              }
              scrollableTarget="scrollableDiv"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Operator')}</TableCell>
                    <TableCell>{t('Facility')}</TableCell>
                    <TableCell>{t('Car park')}</TableCell>
                    <TableCell>{t('Parking started')}</TableCell>
                    <TableCell>{t('Parking ended')}</TableCell>
                    <TableCell>{t('Price')}</TableCell>
                    <TableCell>{t('Internal ID')}</TableCell>
                    <TableCell>{t('Parking attribute')}</TableCell>
                    <TableCell>{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionList?.map((user, index) => {
                    return (
                      <TableRow hover key={index}>
                        <StyledTableCell
                          title={user.Operator}
                          customStyle={true}
                        />
                        <StyledTableCell
                          title={user.Facility}
                          customStyle={true}
                        />
                        <StyledTableCell title={user.CarPark} />

                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            sx={{ width: '100px' }}
                          >
                            {formatTimeDate(user.Started)}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            sx={{ width: '100px' }}
                          >
                            {formatTimeDate(user.Ended)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            sx={{ width: '50px' }}
                          >
                            {user.Price}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            sx={{ width: '250px' }}
                          >
                            {user.TransactionGuid}
                          </Typography>
                        </TableCell>

                        <StyledTableCell title={user.ParkingAttribute} />

                        <TableCell>
                          <IconButton color="inherit" size="small">
                            <img src={EditIcon} alt="edit_icon" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>

          <Divider />
        </>
      )}
      {transactionList.length === 0 && <EmptyTable />}
    </Card>
  );
};

export default EndUserTransactionsList;
