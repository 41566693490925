import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import { SearchHistoryItemShape } from 'models/Search';
import {
  useSaveSearchMutation,
  useUnSaveSearchMutation,
  useRemoveSearchMutation,
} from 'store/_Api/Search';

type ActionType = (item: SearchHistoryItemShape) => void;

interface Return {
  save: ActionType;
  unSave: ActionType;
  remove: ActionType;
}

const useSearchActions = (): Return => {
  const [saveSearch] = useSaveSearchMutation();
  const [unSaveSearch] = useUnSaveSearchMutation();
  const [removeSearch] = useRemoveSearchMutation();

  const save = (item: SearchHistoryItemShape): void => {
    const url = getApiHrefByRel(item.Links, 'save');
    if (url) {
      saveSearch(url);
    }
  };

  const unSave = (item: SearchHistoryItemShape): void => {
    const url = getApiHrefByRel(item.Links, 'unsave');
    if (url) {
      unSaveSearch(url);
    }
  };

  const remove = (item: SearchHistoryItemShape): void => {
    const deleteUrl = getApiHrefByRel(item.Links, 'delete');
    if (deleteUrl) {
      removeSearch(deleteUrl);
    }
  };

  return {
    save,
    unSave,
    remove,
  };
};

export default useSearchActions;
