import { RouteObject } from 'react-router-dom';
import Loader from 'router/Loader';
import { lazy } from 'react';

const Operators = Loader(lazy(() => import('components/Operators/index')));

const configurationRoutes: RouteObject[] = [
  {
    path: 'operators',
    element: <Operators />,
  },
];

export default configurationRoutes;
