import { LinkShape } from 'models/Link';
import { useCallback, useEffect, useState } from 'react';
import { dispatch, useSelector } from 'store';
import { useBaseApiLinksQuery } from 'store/_Api/Auth';
import {
  setLinks as setLinksInSlice,
  setLink as setLinkInSlice,
  removeLinkByRel as removeLinkFromSlice,
  setOperatorLinks,
} from './BaseLinksSlice';

export type LinksMap = {
  [key: string]: string;
};

type Return = {
  links: undefined | LinkShape[];
  getLink: (rel: string) => string | null;
  setLink: (link: LinkShape) => void;
  setLinksForOperator: (links: LinkShape[] | undefined) => void;
  removeLinkByRel: (rel: string) => void;
  refetch: () => void;
};

/**
 * Hook that stores the base links. Har auto-loading of links using the useBaseApiLinksQuery,
 * and provides functionality for:
 * - getting link by given rel
 * - adding new links
 * - removing links
 */
const useBaseLinks = (): Return => {
  const { links, map, operatorLinks } = useSelector((state) => state.baseLinks);

  // If we already have a map, it means the request was already made,
  // so we just skip it
  const [skip, setSkip] = useState(!!map);
  const {
    data: response,
    refetch: reload,
    isUninitialized,
  } = useBaseApiLinksQuery(undefined, {
    skip,
  });

  const reset = () => {
    dispatch(setOperatorLinks(undefined));
    if (isUninitialized === false) {
      setSkip(false);
      reload();
    }
  };

  useEffect(() => {
    /**
     * When we get a response, we set the links state to the received links
     */
    if (response) {
      dispatch(setLinksInSlice(response?.Links ?? []));
    }
  }, [response]);

  /**
   * Returns link by given rel from the map,
   * provided that is exists
   */
  const getLink = useCallback(
    (rel: string): string | null => {
      if (operatorLinks?.map) {
        // We first try to retrieve link from operator links map if this exists
        const link = operatorLinks.map[rel];
        if (link) {
          return link;
        }
      }
      if (!map) {
        return null;
      }
      return map[rel] ?? null;
    },
    [map, operatorLinks?.map],
  );

  /**
   * Adds a new link in the internal state,
   * with the given rel and href
   */
  const setLink = (link: LinkShape): void => {
    dispatch(setLinkInSlice(link));
  };

  /**
   * Removes link with the given rel from the state,
   * provided it exists
   */
  const removeLinkByRel = (rel: string): void => {
    dispatch(removeLinkFromSlice(rel));
  };

  /**
   * Sets the given links in the Base Links State.
   * This has its own function because it excludes the self link
   */
  const setLinksForOperator = useCallback(
    (links: LinkShape[] | undefined): void => {
      dispatch(setOperatorLinks(links));
    },
    [],
  );

  return {
    links,
    getLink,
    setLink,
    setLinksForOperator,
    removeLinkByRel,
    refetch: reset,
  };
};

export default useBaseLinks;
