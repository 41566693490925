import { LinkShape } from 'models/Link';
import { LinksMap } from 'store/Auth/Links/useBaseLinks';

export const makeMapForLinks = (links: LinkShape[]): LinksMap => {
  const newMap: LinksMap = {};
  let i = 0;
  while (i < links.length) {
    const link = links[i];
    i++;
    const { Rel, Href } = link;
    newMap[Rel] = Href;
  }
  return newMap;
};
