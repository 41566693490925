import { FC, ReactNode } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type Props = {
  title:
    | 'Vehicles'
    | 'Transactions'
    | 'Customers'
    | 'Users'
    | 'Subscriptions'
    | 'Contracts'
    | 'Saved searches'
    | 'Recent searches';
  children: ReactNode | ReactNode[];
};
export const SearchBoxItemGroup: FC<Props> = ({
  title,
  children,
}): JSX.Element => {
  const theme = useTheme<Theme>();
  const { t } = useTranslation();

  return (
    <Grid
      flexDirection="column"
      xs={12}
      md={12}
      lg={12}
      display="flex"
      item
      marginTop={theme.margins.xSmall}
    >
      <Typography variant="h5" component="h5">
        {t(title)}
      </Typography>
      <Box padding={theme.margins.tiny}>{children}</Box>
    </Grid>
  );
};
