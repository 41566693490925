import { RouteObject } from 'react-router-dom';
import Loader from 'router/Loader';
import { lazy } from 'react';

const LoginPage = Loader(lazy(() => import('content/pages/Guest/Login')));

const loginRouter: RouteObject[] = [
  {
    path: 'login',
    element: <LoginPage />,
  },
];

export default loginRouter;
