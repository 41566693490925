import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AssignCameraToLaneModel,
  CameraConnectionFormModel,
  CameraFormModel,
} from 'models/Facilities';
import { baseQuery } from '../..';
import {
  AddExitLaneModel,
  LaneApiResponse,
} from '../../../../models/Facilities';

export type PostType = {
  url: string;
  payload:
    | CameraFormModel
    | AddExitLaneModel
    | AssignCameraToLaneModel
    | CameraConnectionFormModel;
};

export const LanesApi = createApi({
  reducerPath: 'lanes',
  tagTypes: ['entry-lanes', 'exit-lane'],
  baseQuery,
  endpoints: (builder) => ({
    getLanes: builder.query<LaneApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['entry-lanes', 'exit-lane'],
    }),

    addEntryExitLane: builder.mutation<LaneApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['entry-lanes', 'exit-lane'],
    }),
    assignCameraToLane: builder.mutation<LaneApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['entry-lanes', 'exit-lane'],
    }),
    updateLaneNotes: builder.mutation<LaneApiResponse, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['entry-lanes', 'exit-lane'],
    }),
  }),
});

export const {
  useGetLanesQuery,
  useAddEntryExitLaneMutation,
  useAssignCameraToLaneMutation,
  useUpdateLaneNotesMutation,
} = LanesApi;
