import { FC, useState, useEffect } from 'react';
import { AccountCircleIcon } from '../../SearchBox.Icons';
import { SearchBoxItem } from '../../SearchBox.Item';
// import { SubscriptionShape } from 'models/EndUsers';
import { SubscriptionPlan } from 'models/Subscriptions';
import SubscriptionModal from 'components/Subscription/Modal';
import { useGetSearchResultsQuery } from 'store/_Api/Search';
import { SearchBoxItemGroup } from 'components/_shared/Search/SearchBox.ItemGroup';
// import { SearchItemShape } from 'models/Search';

interface Props {
  searchUrl: string;
  setSectionState: (isLoading: boolean, hasResults: boolean) => void;
}

const SubscriptionSearchResults: FC<Props> = ({
  searchUrl,
  setSectionState,
}): JSX.Element => {
  const [viewSubscription, setViewSubscription] = useState<
    SubscriptionPlan | undefined
  >(undefined);

  const [list, setList] = useState<SubscriptionPlan[]>([]);

  const { data, isLoading } = useGetSearchResultsQuery(searchUrl);

  useEffect(() => {
    if (data && data.Items.length > 0) {
      const items = data.Items as unknown as SubscriptionPlan[];
      setList(items);
      setSectionState(isLoading, items.length > 0);
    }
  }, [data, setSectionState, isLoading]);

  const viewSubscriptionModal = (subscription: SubscriptionPlan) => {
    // const subscriptionModelData = {
    //   Name: subscription.Name,
    //   Description: '',
    //   Status: subscription.Status.toString(),
    // };
    setViewSubscription(subscription);
  };

  if (isLoading || list.length === 0) {
    return <></>;
  }

  return (
    <SearchBoxItemGroup title="Subscriptions">
      {list.map((subscription, index) => (
        <div key={index}>
          <SearchBoxItem
            text={subscription.Name}
            icon={<AccountCircleIcon />}
            viewSubscriptionModal={() => viewSubscriptionModal(subscription)}
          />
          {viewSubscription && (
            <SubscriptionModal
              isSubscriptionEditModal={true}
              subscription={viewSubscription}
              handleClose={() => setViewSubscription(undefined)}
            />
          )}
        </div>
      ))}
    </SearchBoxItemGroup>
  );
};

export default SubscriptionSearchResults;
