import { useState, useEffect, FC } from 'react';
import { Card } from '@mui/material';
import { EndUserShape, SubscriptionShape } from 'models/EndUsers';

import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import EndUsersSubscriptionList from './List/EndUserSubscriptionList';
import { useSubscriptionListQuery } from 'store/_Api/Users';

type Props = {
  user: EndUserShape;
};

const EndUserSubscriptionTab: FC<Props> = ({ user }): JSX.Element => {
  const [list, setList] = useState<SubscriptionShape[]>([]);
  const [hasNextLink, setHasNextLink] = useState(true);
  const subscriptionUrl = getApiHrefByRel(user?.Links, 'subscriptions');
  const [link, setLink] = useState('');

  useEffect(() => {
    if (subscriptionUrl) {
      setLink(subscriptionUrl);
    }
  }, [subscriptionUrl]);

  const { data } = useSubscriptionListQuery(link, { skip: !link });
  const subscriptionListData = data?.Items;
  const listActionLinks = data?.Links;

  const onScroll = () => {
    if (listActionLinks) {
      const nextLink = getApiHrefByRel(listActionLinks, 'next');
      if (nextLink && hasNextLink) {
        setLink(nextLink);
      } else {
        setHasNextLink(false);
      }
    }
  };

  useEffect(() => {
    if (subscriptionListData) {
      setList((prevState) => [...prevState, ...subscriptionListData]);
      return;
    }
  }, [subscriptionListData, data, link]);

  return (
    <Card sx={{ width: '100%' }}>
      <EndUsersSubscriptionList list={list} handleFetchList={onScroll} />
    </Card>
  );
};

export default EndUserSubscriptionTab;
