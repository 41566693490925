import { useState, useEffect } from 'react';
import { UserApiLoginPostData } from 'Api/User/UserApi';

import { LoginResponseShape, UserAccountShape } from 'models/User';

import {
  useGetProfileQuery,
  useLoginMutation,
  useLogoutMutation,
} from 'store/_Api/Auth';
import { useBaseLinks } from 'store/Auth';
import { skipToken } from '@reduxjs/toolkit/query/react';

type Return = {
  isLoggedIn: boolean;
  login: (data: UserApiLoginPostData) => Promise<LoginResponseShape | string>;
  logout: () => Promise<void>;
  profile: UserAccountShape | undefined;
};

const useAuth = (): Return => {
  const { getLink, refetch } = useBaseLinks();
  const [accountUrl, setAccountUrl] = useState<string | undefined>();

  useEffect(() => {
    const url = getLink('user-account');
    if (url) {
      setAccountUrl(url);
    }
  }, [getLink]);

  const { data: profile } = useGetProfileQuery(accountUrl ?? skipToken);

  const [login] = useLoginMutation();
  const [logout] = useLogoutMutation();

  const signOutLink = getLink('sign-out');
  const isLoggedIn = !!signOutLink;

  const handleLogin = async (
    data: UserApiLoginPostData,
  ): Promise<LoginResponseShape | string> => {
    return new Promise((resolve, reject) => {
      const endpoint = getLink('cookie-auth');
      if (!endpoint) {
        console.error('Could not get endpoint when trying to run login');
        reject('Not being able to find the login link');
        return;
      }

      try {
        const response = login({
          url: endpoint,
          userData: data,
        }).unwrap();
        refetch();
        resolve(response);
      } catch (error) {
        console.error('Cought error when trying to login ->', error);
        reject(error);
      }
    });
  };

  const handleLogout = async () => {
    const endpoint = getLink('sign-out');
    if (endpoint) {
      await logout(endpoint);
    }
  };

  return {
    isLoggedIn,
    profile,
    login: handleLogin,
    logout: handleLogout,
  };
};

export default useAuth;
