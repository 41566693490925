import React, { useState, useEffect, FC } from 'react';
import { Box, Collapse, Switch, FormControlLabel } from '@mui/material';
import { ContractShape, EndUserShape } from 'models/EndUsers';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import { useContractListQuery } from 'store/_Api/Users';

interface Props {
  user: EndUserShape;
}

const EndUserContractsTab: FC<Props> = ({ user }): JSX.Element => {
  const [contractList, setContractList] = useState<ContractShape[]>([]);
  const [checked, setChecked] = React.useState(false);
  const contractUrl = getApiHrefByRel(user.Links, 'contracts') ?? '';

  const [link, setLink] = useState(contractUrl);

  useEffect(() => {
    setLink(contractUrl);
  }, [contractUrl]);

  const { data } = useContractListQuery(link, { skip: !link });

  useEffect(() => {
    if (data) {
      setContractList(data);
    }
  }, [data]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box sx={{ pl: 4 }}>
      <h1 onClick={handleChange}>
        {contractList[0] ? contractList[0]?.OperatorName : 'No data to display'}
      </h1>
      <Box>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} />}
          label="Show"
        />
        <Box
          sx={{
            '& > :not(style)': {
              display: 'flex',
              justifyContent: 'space-around',
              height: 120,
              width: 250,
            },
          }}
        >
          <div>
            <Collapse in={checked}>
              <h1>Hello from collapse</h1>
            </Collapse>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default EndUserContractsTab;
