import { useState, ChangeEvent, useMemo, useEffect } from 'react';
import {
  FormControl,
  Grid,
  Select,
  MenuItem,
  useTheme,
  Typography,
  ListItemIcon,
  ListItemText,
  TextField,
  InputAdornment,
  ListSubheader,
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';

import { OperatorResponseItem } from 'models/Operator';

import { useBaseLinks } from 'store/Auth';
import { useGetOperatorsQuery } from 'store/_Api/Analytics';
import useSelectedOperator from 'store/Analytics/Operator/useSelectedOperator';
import { AnalyticsOperator } from 'models/Analytics/Operator';

const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

const OperatorSelector = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { getLink } = useBaseLinks();
  const operatorsLink = getLink('operators') ?? '';
  const { data: operatorsResponse } = useGetOperatorsQuery(operatorsLink, {
    skip: !operatorsLink,
  });

  const list = useMemo(
    () => operatorsResponse?.Items ?? [],
    [operatorsResponse?.Items],
  );

  const [filteredList, setFilteredList] =
    useState<OperatorResponseItem[]>(list);
  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  const [query, setQuery] = useState('');
  const { selected, select } = useSelectedOperator();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    getFilteredOperators(event.target.value);
  };

  const getFilteredOperators = (searchKey: string) => {
    const lowercasedValue = searchKey.toLowerCase().trim();
    if (lowercasedValue === '') {
      setFilteredList(list);
    }
    const filteredData = list.filter(
      (operator) =>
        operator.Name?.toLowerCase().includes(lowercasedValue) && operator,
    );
    setFilteredList(filteredData);
  };

  return (
    <>
      <Grid display="flex" alignItems="center" marginRight={1}>
        {list && list?.length > 0 && (
          <>
            <Typography sx={{ width: '140px' }}>Selected operator:</Typography>
            <FormControl sx={{ minWidth: 140 }}>
              <Select
                style={{
                  backgroundColor: theme.colors.alpha.black[10],
                  color: theme.colors.alpha.black[100],
                  fontWeight: 'bold',
                }}
                MenuProps={MenuProps}
                value={selected?.OperatorGuid ?? 'none'}
                onClose={() => setQuery('')}
                renderValue={() => selected?.Name ?? 'none'}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    sx={{ flexGrow: 1, display: 'flex', width: 300, mb: 1 }}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchTwoToneIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={query}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key !== 'Escape') {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                      }
                    }}
                    placeholder={t('Search operator...')}
                  />
                </ListSubheader>
                <MenuItem
                  value="none"
                  selected={true}
                  onClick={() => select(undefined, true)}
                >
                  {t('None')}
                </MenuItem>
                {filteredList?.map((operator: AnalyticsOperator) => (
                  <MenuItem
                    key={operator.OperatorGuid}
                    value={operator.OperatorGuid}
                    onClick={() => select(operator, true)}
                  >
                    <ListItemIcon>
                      <img
                        src={operator.LogoUri}
                        alt=""
                        width="20px"
                        height="20px"
                      />
                    </ListItemIcon>
                    <ListItemText> {operator.Name}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Grid>
    </>
  );
};

export default OperatorSelector;
