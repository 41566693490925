import { ApiEndPointsResponseData } from 'Api/Endpoints/index';
import { createApi } from '@reduxjs/toolkit/query/react';
import { SearchApiResponse } from 'models/Search';

import { baseQuery } from '..';

export const SearchApi = createApi({
  reducerPath: 'searchApi',
  tagTypes: ['SearchHistory', 'SavedSearches', 'SearchLinks'],
  baseQuery,
  endpoints: (builder) => ({
    getSearchHistory: builder.query<SearchApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['SearchHistory'],
    }),
    getSavedSearches: builder.query<SearchApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['SavedSearches'],
    }),
    searchText: builder.query<ApiEndPointsResponseData, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['SearchLinks', 'SearchHistory'],
    }),
    saveSearch: builder.mutation<ApiEndPointsResponseData, string>({
      query: (url) => ({
        url: url,
        method: 'POST',
      }),
      invalidatesTags: ['SavedSearches', 'SearchHistory'],
    }),
    unSaveSearch: builder.mutation<ApiEndPointsResponseData, string>({
      query: (url) => ({
        url: url,
        method: 'POST',
      }),
      invalidatesTags: ['SavedSearches', 'SearchHistory'],
    }),
    removeSearch: builder.mutation<ApiEndPointsResponseData, string>({
      query: (url) => ({
        url: url,
        method: 'DELETE',
      }),
      invalidatesTags: ['SavedSearches', 'SearchHistory'],
    }),
    getSearchLinks: builder.query<ApiEndPointsResponseData, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getSearchResults: builder.query<SearchApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
    }),
  }),
});

export const {
  useGetSearchHistoryQuery,
  useGetSavedSearchesQuery,
  useSearchTextQuery,
  useSaveSearchMutation,
  useUnSaveSearchMutation,
  useRemoveSearchMutation,
  useGetSearchLinksQuery,
  useGetSearchResultsQuery,
} = SearchApi;
